import React from 'react';
import { Icon, Breadcrumbs, Hidden } from '@mui/material';
import { NavLink } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
const Breadcrumb = ({ routeSegments }) => {
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
      {routeSegments ? (
        <Hidden xsDown>
          <h4
            style={{
              margin: '0rem',
              textTransform: 'capitalize',
              verticalAlign:'middle',
              paddingBottom:'1px',
              fontSize:'16px'
            }}
          >
            {routeSegments[routeSegments.length - 1]['name']}
          </h4>
          <h4 style={{ margin: '0rem', paddingBottom: '3px', marginLeft: '0.5rem',color:'rgba(52, 49, 76, 0.38)' }}>
            |
          </h4>
        </Hidden>
      ) : null}
      <Breadcrumbs
        separator={<NavigateNextIcon style={{ textAlign: 'center' }} />}
        style={{ display: 'flex', alignItems: 'center', position: 'relative' }}
      >
        <NavLink to="/ai/projects">
          <HomeIcon style={{ textAlign: 'center',marginLeft:'2px',marginBottom:'-3px' }} color="primary"/>
          {/* <Icon className="align-middle ml-2 mb-1" color="primary">
            home
          </Icon> */}
        </NavLink>
        {routeSegments
          ? routeSegments.map((route, index) => {
              return index !== routeSegments.length - 1 ? (
                <NavLink key={index} to={route.path}>
                  <span style={{ textTransform: 'capitalize',color:'rgba(52, 49, 76, 0.54)' }}>
                    {route.name}
                  </span>
                </NavLink>
              ) : (
                <span key={index} style={{ textTransform: 'capitalize',color:'rgba(52, 49, 76, 0.54)' }}>
                  {route.name}
                </span>
              );
            })
          : null}
      </Breadcrumbs>
    </div>
  );
};

export default Breadcrumb;
