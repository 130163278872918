import React, { useState } from 'react';
import {
  Box,
  Card,
  Stack,
  CardContent,
  CardMedia,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
  TextField,
  Icon,
  Dialog,
  DialogActions,
  Paper,
  InputLabel,
  Select,
  Divider,
  FormControl,
  MenuItem
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Breadcrumb from './Breadcrumb';
import { CardActionArea, Typography } from '@mui/material';
import ViewProject from './viewproject';
const ExperimentsPage = () => {
  const [open, setOpen] = React.useState(false);
  let navigate = useNavigate();
  const handleClickOpen = (res) => {
    res.id == 1 ? setOpen(true) : navigate(`/ai/${'2'}`);
  };
  const handle = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [us, setuse] = useState(list);
  const addUser = (user) => {
    user.id = us.length + 1;
    setuse([...us, user]);
  };
  const initialFormState = { id: null, name: '', img: 'settings' };

  const [user, setUser] = useState(initialFormState);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  };
  ///filtering
  const [datalist, setdatalist] = React.useState('All');
  const handleInputChangef = (event) => {
    setdatalist(event.target.value);
    filtering(event.target.value);
  };
  const [filt, setfilt] = React.useState(list);

  const filtering = (e) => {
    var data = list.filter(function (el) {
      if (e == 'All') {
        return filt;
      } else {
        return el.name == e;
      }
    });
    setfilt(data);
    // console.log(data);
  };
  return (
    <Box
      sx={{
        p: 4
      }}
    >
      <Breadcrumb
        routeSegments={[
          { name: 'projects', path: 'ai/projects' }
          //{ name: 'Autocomplete' }
        ]}
      />
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <FormControl variant="outlined" margin="dense" style={{minWidth:'50%'}} size="small">
            <InputLabel id="Select-Measure">Select Measure</InputLabel>
            <Select
              labelId="Select-Measure"
              id="Select-Measure"
              value={datalist}
              name="datalist"
              onChange={handleInputChangef}
              label="Select Measure"
            >
              <MenuItem value={'All'}>All</MenuItem>
              <MenuItem value={'Readme first'}>Readme first</MenuItem>
              <MenuItem value={'Data pipelines'}>Data pipelines</MenuItem>
              <MenuItem value={'Finance models'}>Finance models</MenuItem>
              <MenuItem value={'Healthcare Models'}>Healthcare Models</MenuItem>
              <MenuItem value={'Resource models'}>Resource models</MenuItem>
              <MenuItem value={'Readme firsts'}>Readme firsts</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Box sx={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Button variant="outlined" onClick={handle}>
              Add
            </Button>
          </Box>
        </Grid>
      </Grid>

      {/* <Grid container spacing={1}>
        {us.map((res, index) => (

          <Grid item xs={6} sm={3} md={2} key={index}>
            <Card
              // sx={{ maxWidth: 180 }}
              sx={{ minHeight: 160 }}
              key={index}
              //style={{ margin: '10px' }}
              onClick={() => handleClickOpen(res)}
            >
              <CardActionArea>
                <div style={{ textAlign: 'center', alignItems: 'center' }}>
                  <Icon style={{ fontSize: '100px' }} color="inherit">
                    {res.img}
                  </Icon>
                </div>
                <CardContent>
                  <Typography
                    style={{ textAlign: 'center' }}
                    variant="body2"
                    color="text.secondary"
                  >
                    {res.name}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid> */}

      {filt.map((res, index) => (
        <div key={index}>
          <Stack direction="row" spacing={2} sx={{ m: 1 }}>
            <h4 style={{ marginBottom: '1px' }}>{res.name}</h4>
            <ViewProject />
          </Stack>

          <Grid container spacing={1}>
            {res.sub.map((res, index) => (
              <Grid item xs={6} sm={3} md={2} key={index}>
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}
                  key={index}
                  onClick={() => handleClickOpen(res)}
                >
                  <CardActionArea>
                    <div style={{ textAlign: 'center', alignItems: 'center' }}>
                      <Icon style={{ fontSize: '100px' }} color="inherit">
                        {res.img}
                      </Icon>
                    </div>
                    <CardContent>
                      <Typography
                        style={{ textAlign: 'center' }}
                        variant="body2"
                        color="text.secondary"
                      >
                        {res.name}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>
      ))}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle style={{ textAlign: 'center', alignItems: 'center' }}>
          Add new Project
        </DialogTitle>
        <DialogContent style={{ alignContent: 'center', textAlign: 'center' }}>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              if (!user.name) return;
              addUser(user);
              setUser(initialFormState);
              handleClose();
            }}
          >
            <TextField
              label="Project name"
              name="name"
              size="small"
              variant="outlined"
              value={user.name}
              onChange={handleInputChange}
            />
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit">Save</Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
export default ExperimentsPage;
const list = [
  // {
  //   name: '',
  //   id: '1',
  //   img: 'add_circle_outline_icon'
  // },
  {
    name: 'Readme first',
    id: '8',
    img: 'settings',
    sub: [
      {
        name: 'Test.csv',
        img: 'picture_as_pdf'
      },

      {
        name: '0.0 Hitch-hikers guide to ML and Numtra (V 1.3).pdf',
        img: 'picture_as_pdf'
      },

      {
        name: '5.0 Self-Service ML with Numtra.pdf',
        img: 'picture_as_pdf'
      },

      {
        name: '4.0 ML Use Cases by Industry.pdf',
        img: 'picture_as_pdf'
      },

      {
        name: '3.0 Types of ML.pdf',
        img: 'picture_as_pdf'
      },

      {
        name: '2.0 A Simple ML Case Study.pdf',
        img: 'picture_as_pdf'
      },

      {
        name: '1.0 An Intro to AI & ML .pdf',
        img: 'picture_as_pdf'
      }
    ]
  },
  {
    name: 'Data pipelines',
    id: '2',
    img: 'settings',
    sub: [
      {
        name: 'Classification',
        img: 'folder'
      },
      {
        name: 'Regression',
        img: 'folder'
      },
      {
        name: 'clustering',
        img: 'folder'
      }
    ]
  },
  {
    name: 'Finance models',
    id: '3',
    img: 'settings',
    sub: [
      {
        name: 'Life Insurance Risk Assessment ',

        img: 'folder'
      },
      {
        name: 'Santander Product Recommendation - Regression',

        img: 'folder'
      },
      {
        name: 'Credit Card Fraud Detection - Regression ',

        img: 'folder'
      },
      {
        name: 'Loan Pay-off Prediction - Classification ',

        img: 'folder'
      },
      {
        name: 'Sales Forecasting - Time Series ',

        img: 'folder'
      },
      {
        name: 'Financial Customer Segmentation - Clustering ',

        img: 'folder'
      },
      {
        name: 'New York Stock Exchange - Regression ',

        img: 'folder'
      },
      {
        name: 'Bank Deposit-Classification',

        img: 'folder'
      },
      {
        name: 'Predict Highest Income-Regression',

        img: 'folder'
      },
      {
        name: 'Increase Customer Acquisition - Classification',

        img: 'folder'
      },
      {
        name: 'Loan Repayments Model - Regression',

        img: 'folder'
      },
      {
        name: 'Marketing Targets for Banks - Classification',

        img: 'folder'
      },
      {
        name: 'Risk Based Interest Pricing - Classification',

        img: 'folder'
      }
    ]
  },
  {
    name: 'Healthcare Models',
    id: '4',
    img: 'settings',
    sub: [
      {
        name: 'Hospital LOS',

        img: 'folder'
      },
      {
        name: 'Fetal Health Classification ',

        img: 'folder'
      },
      {
        name: 'Heart Failure Prediction Model',

        img: 'folder'
      },
      {
        name: 'Healthcare Expenditure Time Series ',

        img: 'folder'
      },
      {
        name: 'Patient Charges Clustering Model',

        img: 'folder'
      },
      {
        name: 'Patient Charges - Regression',

        img: 'folder'
      },
      {
        name: 'Corona Virus App',

        img: 'folder'
      },
      {
        name: 'Appointment No-Show Prediction - Regression',

        img: 'folder'
      },
      {
        name: 'Claims Denial Propensity Scoring',

        img: 'folder'
      },
      {
        name: 'RCM - Claims Management - Classification',

        img: 'folder'
      },
      {
        name: 'ABCDADADA',

        img: 'prediction'
      },
      {
        name: 'LOS-b',

        img: 'prediction'
      }
    ]
  },
  {
    name: 'Resource models',
    id: '5',
    img: 'settings',
    sub: [
      {
        name: 'Zillow Rent Index - Regression ',

        img: 'folder'
      },
      {
        name: 'Real Estate Price Prediction - Regression ',

        img: 'folder'
      },
      {
        name: 'Boston Housing App ',

        img: 'folder'
      }
    ]
  },
  {
    name: 'Readme firsts',
    id: '6',
    img: 'settings',
    sub: []
  }
];
