import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {
  MenuItem,
  Grid,
  FormControl,
  InputLabel,
  Select
} from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';

export default function Dataspliter() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <MenuItem onClick={handleClickOpen}>SPLIT</MenuItem>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Dataset Spliter</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="OUTPUT NAME 1"
                defaultValue="train"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="OUTPUT NAME 2"
                defaultValue="test"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              >
                <InputLabel id="demo-simple-select-label">
                  TARGET COLUMN
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                >
                  <MenuItem value={10}>id</MenuItem>
                  <MenuItem value={20}>LIMIT_BAL</MenuItem>
                  <MenuItem value={30}>SEX</MenuItem>
                  <MenuItem value={40}>EDUCATION</MenuItem>
                  <MenuItem value={50}>MARRIAGE</MenuItem>
                  <MenuItem value={60}>AGE</MenuItem>
                  <MenuItem value={70}>PAY_0</MenuItem>
                  <MenuItem value={80}>PAY_2</MenuItem>
                  <MenuItem value={90}>PAY_3</MenuItem>
                  <MenuItem value={100}>PAY_4</MenuItem>
                  <MenuItem value={110}>PAY_5</MenuItem>
                  <MenuItem value={120}>PAY_6</MenuItem>
                  <MenuItem value={130}>BILL_AMT1</MenuItem>
                  <MenuItem value={140}>BILL_AMT2</MenuItem>
                  <MenuItem value={150}>BILL_AMT3</MenuItem>
                  <MenuItem value={160}>BILL_AMT4</MenuItem>
                  <MenuItem value={170}>BILL_AMT5</MenuItem>
                  <MenuItem value={180}>default payment next month</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="FOLD COLUMN"
                defaultValue=""
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              >
                <InputLabel id="demo-simple">
                  TIME COLUMN
                </InputLabel>
                <Select
                  labelId="demo-simple"
                  id="demo-simple"
                >
                  <MenuItem value={10}>id</MenuItem>
                  <MenuItem value={20}>LIMIT_BAL</MenuItem>
                  <MenuItem value={30}>SEX</MenuItem>
                  <MenuItem value={40}>EDUCATION</MenuItem>
                  <MenuItem value={50}>MARRIAGE</MenuItem>
                  <MenuItem value={60}>AGE</MenuItem>
                  <MenuItem value={70}>PAY_0</MenuItem>
                  <MenuItem value={80}>PAY_2</MenuItem>
                  <MenuItem value={90}>PAY_3</MenuItem>
                  <MenuItem value={100}>PAY_4</MenuItem>
                  <MenuItem value={110}>PAY_5</MenuItem>
                  <MenuItem value={120}>PAY_6</MenuItem>
                  <MenuItem value={130}>BILL_AMT1</MenuItem>
                  <MenuItem value={140}>BILL_AMT2</MenuItem>
                  <MenuItem value={150}>BILL_AMT3</MenuItem>
                  <MenuItem value={160}>BILL_AMT4</MenuItem>
                  <MenuItem value={170}>BILL_AMT5</MenuItem>
                  <MenuItem value={180}>default payment next month</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="RANDOM SPEED"
                defaultValue="1234"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            cacel
          </Button>
          <Button onClick={handleClose} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
