import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { FormHelperText, Grid } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';

export default function Deploy() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        fullWidth
        size="small"
        color="secondary"
        variant="contained"
        onClick={handleClickOpen}
      >
        DEPLOY (LOCAL & CLOUD)
      </Button>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Deploy</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              Deployment for
            </Grid>
            <Grid item xs={9}>
              <TextField
                autoFocus
                margin="dense"
                fullWidth
                id="name"
                size="small"
                label="Deployment for"
                defaultValue="cc default settings"
                fullWidth
              />
            </Grid>

            <Grid item xs={3}></Grid>
            <Grid item xs={9}>
              <FormHelperText> select one of the options</FormHelperText>
            </Grid>

            <Grid item xs={3}>
              Amazon Lambda
            </Grid>
            <Grid item xs={9}>
              <TextField
                autoFocus
                margin="dense"
                fullWidth
                id="name"
                size="small"
                label="Amazon Lambda"
                defaultValue="Use you amazon account to host the pipeline on amazon's lambda computing service requires an existing amazon account"
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              REST Server
            </Grid>
            <Grid item xs={9}>
              <TextField
                autoFocus
                margin="dense"
                fullWidth
                id="name"
                size="small"
                label="REST Server"
                defaultValue="Deploy your experiment using a java-powered REST API"
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
