const Logo = (props) => (
  <img
    alt="Logo"
    src="/static/images/Asset-5.svg"
    width='200px'
    {...props}
  />
);

export default Logo;
