import React from 'react';
import {
  Grid,
  TextField,
  CircularProgress,
  Button,
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  MenuItem
} from '@mui/material';
import Deploy from './deploy'
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import Breadcrumb from './Breadcrumb'
const Experiment = (props) => {
  return (
    <Box
    sx={{
      p: 4
    }}
  >
    <Breadcrumb
      routeSegments={[
        { name: 'Experiments', path: '/ai/experiments' },
        { name: 'Experiments Launch' }
      ]}
    />
    <br/>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="stretch"
      >
        <Grid item xs={12} sm={4}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="DISPLAY NAME"
                defaultValue="DISPLAY NAME"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="DATASET"
                defaultValue="CreditCard-train.csv"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="ROWS"
                defaultValue="24K"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="COLUMNS"
                defaultValue="25"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                label="DROPPED COLUMN"
                defaultValue=""
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                label="VALIDATION DATASET"
                defaultValue=""
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                label="TEST DATASET"
                defaultValue=""
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="TARGET COLUMN"
                defaultValue="Default payment next"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="FOLD COLUMN"
                defaultValue=""
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="WEIGHT COLUMN"
                defaultValue=""
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="TIME COLUMN"
                defaultValue="[OFF]"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <p>TYPE</p>
              <h3>Bool</h3>
            </Grid>
            <Grid item xs={12} sm={3}>
              <p>count</p>
              <h3>23999</h3>
            </Grid>
            <Grid item xs={12} sm={3}>
              <p>UNIQUE</p>
              <h3>3</h3>
            </Grid>
            <Grid item xs={12} sm={3}>
              <p>TARGET FREQ</p>
              <h3>5369</h3>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4}>
          <List
            dense
            component="nav"
            aria-label="main mailbox folders"
            style={{ margin: '0px 10px' }}
          >
              <Deploy/>
            {ll.map((res) => (
              <Button key={res.name} fullWidth size='small' color="secondary" variant="contained">
                {res.name}
              </Button>
            ))}
            {/* {ll.map((res) => 
              <ListItem button key={res.name}>
                <ListItemText
                  style={{ textAlign: 'center' }}
                  primary={res.name}
                />
              </ListItem>
            )} */}
          </List>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={3}>
              <CircularProgressWithLabel
                variant="determinate"
                value={100}
              ></CircularProgressWithLabel>
              <p>ACCURACY</p>
            </Grid>
            <Grid item xs={12} sm={3}>
              <CircularProgressWithLabel
                variant="determinate"
                value={100}
              ></CircularProgressWithLabel>
              <p>TIME</p>
            </Grid>
            <Grid item xs={12} sm={3}>
              <CircularProgressWithLabel
                variant="determinate"
                value={100}
              ></CircularProgressWithLabel>
              <p>INTERPETABLITY</p>
            </Grid>
            <Grid item xs={12} sm={3}>
              <CircularProgressWithLabel
                variant="determinate"
                value={100}
              ></CircularProgressWithLabel>
              <p>SCORER</p>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button variant="outlined" size="small" color='secondary'>
                CLASSIFICATION
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button variant="outlined" size="small">
                REPRODUCIBLE
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button variant="outlined" size="small">
                GPUS DISABLED
              </Button>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Chart
                options={{
                  series: [
                    {
                      name: 'Desktops',
                      data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
                    }
                  ],
                  chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                      enabled: false
                    }
                  },
                  dataLabels: {
                    enabled: false
                  },
                  stroke: {
                    curve: 'straight'
                  },
                  title: {
                    text: 'CPU/MEMORY',
                    align: 'left'
                  },
                  grid: {
                    row: {
                      colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                      opacity: 0.5
                    }
                  },
                  xaxis: {
                    categories: ['cpu', 'memory']
                  }
                }}
                series={[
                  {
                    name: 'Desktops',
                    data: [0, 5, 10, 10, 10, 15, 20]
                  }
                ]}
                type="line"
                width="350"
              />

              {/* <Button variant="outlined" href='/app/experiment' fullWidth>LAUNCH EXPERIMENT</Button> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <Divider />
      <br />
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="stretch"
      >
        <Grid item xs={12} sm={4}>
          <Chart
            options={{
              series: [
                {
                  name: 'Net Profit',
                  data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
                },
                {
                  name: 'Revenue',
                  data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
                },
                {
                  name: 'Free Cash Flow',
                  data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
                }
              ],
              chart: {
                type: 'bar',
                height: 350
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  columnWidth: '55%',
                  endingShape: 'rounded'
                }
              },
              dataLabels: {
                enabled: false
              },
              stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
              },
              xaxis: {
                categories: [
                  'Feb',
                  'Mar',
                  'Apr',
                  'May',
                  'Jun',
                  'Jul',
                  'Aug',
                  'Sep',
                  'Oct'
                ]
              },
              yaxis: {
                title: {
                  text: '$ (thousands)'
                }
              },
              fill: {
                opacity: 1
              },
              tooltip: {
                y: {
                  formatter: function (val) {
                    return '$ ' + val + ' thousands';
                  }
                }
              }
            }}
            series={[
              {
                name: 'Net Profit',
                data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
              },
              {
                name: 'Revenue',
                data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
              },
              {
                name: 'Free Cash Flow',
                data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
              }
            ]}
            type="bar"
            width="350"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Chart
            options={{
              chart: {
                height: 450,
                type: 'rangeBar'
              },
              plotOptions: {
                bar: {
                  horizontal: true,
                  barHeight: '80%'
                }
              },
              xaxis: {
                type: 'datetime'
              },
              stroke: {
                width: 1
              },
              fill: {
                type: 'solid',
                opacity: 0.6
              },
              legend: {
                position: 'top',
                horizontalAlign: 'left'
              }
            }}
            series={[
              {
                name: '',
                data: [
                  {
                    x: 'Design',
                    y: [
                      new Date('2019-03-05').getTime(),
                      new Date('2019-03-08').getTime()
                    ]
                  },
                  {
                    x: 'Code',
                    y: [
                      new Date('2019-03-02').getTime(),
                      new Date('2019-03-05').getTime()
                    ]
                  },
                  {
                    x: 'Code',
                    y: [
                      new Date('2019-03-05').getTime(),
                      new Date('2019-03-07').getTime()
                    ]
                  },
                  {
                    x: 'Test',
                    y: [
                      new Date('2019-03-03').getTime(),
                      new Date('2019-03-09').getTime()
                    ]
                  },
                  {
                    x: 'Test',
                    y: [
                      new Date('2019-03-08').getTime(),
                      new Date('2019-03-11').getTime()
                    ]
                  },
                  {
                    x: 'Validation',
                    y: [
                      new Date('2019-03-11').getTime(),
                      new Date('2019-03-16').getTime()
                    ]
                  },
                  {
                    x: 'Design',
                    y: [
                      new Date('2019-03-01').getTime(),
                      new Date('2019-03-03').getTime()
                    ]
                  }
                ]
              },
              {
                name: '',
                data: [
                  {
                    x: 'Design',
                    y: [
                      new Date('2019-03-02').getTime(),
                      new Date('2019-03-05').getTime()
                    ]
                  },
                  {
                    x: 'Test',
                    y: [
                      new Date('2019-03-06').getTime(),
                      new Date('2019-03-16').getTime()
                    ],
                    goals: [
                      {
                        name: 'Break',
                        value: new Date('2019-03-10').getTime(),
                        strokeColor: '#CD2F2A'
                      }
                    ]
                  },
                  {
                    x: 'Code',
                    y: [
                      new Date('2019-03-03').getTime(),
                      new Date('2019-03-07').getTime()
                    ]
                  },
                  {
                    x: 'Deployment',
                    y: [
                      new Date('2019-03-20').getTime(),
                      new Date('2019-03-22').getTime()
                    ]
                  },
                  {
                    x: 'Design',
                    y: [
                      new Date('2019-03-10').getTime(),
                      new Date('2019-03-16').getTime()
                    ]
                  }
                ]
              },
              {
                name: '',
                data: [
                  {
                    x: 'Code',
                    y: [
                      new Date('2019-03-10').getTime(),
                      new Date('2019-03-17').getTime()
                    ]
                  },
                  {
                    x: 'Validation',
                    y: [
                      new Date('2019-03-05').getTime(),
                      new Date('2019-03-09').getTime()
                    ],
                    goals: [
                      {
                        name: 'Break',
                        value: new Date('2019-03-07').getTime(),
                        strokeColor: '#CD2F2A'
                      }
                    ]
                  }
                ]
              }
            ]}
            type="rangeBar"
            width="350"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Chart
            options={{
              series: [
                {
                  // data: series.monthDataSeries1.prices
                }
              ],
              chart: {
                height: 350,
                type: 'line',
                id: 'areachart-2'
              },
              annotations: {
                yaxis: [
                  {
                    y: 8200,
                    borderColor: '#00E396',
                    label: {
                      borderColor: '#00E396',
                      style: {
                        color: '#fff',
                        background: '#00E396'
                      },
                      text: 'Support'
                    }
                  },
                  {
                    y: 8600,
                    y2: 9000,
                    borderColor: '#000',
                    fillColor: '#FEB019',
                    opacity: 0.2,
                    label: {
                      borderColor: '#333',
                      style: {
                        fontSize: '10px',
                        color: '#333',
                        background: '#FEB019'
                      },
                      text: 'Y-axis range'
                    }
                  }
                ],
                xaxis: [
                  {
                    x: new Date('23 Nov 2017').getTime(),
                    strokeDashArray: 0,
                    borderColor: '#775DD0',
                    label: {
                      borderColor: '#775DD0',
                      style: {
                        color: '#fff',
                        background: '#775DD0'
                      },
                      text: 'Anno Test'
                    }
                  },
                  {
                    x: new Date('26 Nov 2017').getTime(),
                    x2: new Date('28 Nov 2017').getTime(),
                    fillColor: '#B3F7CA',
                    opacity: 0.4,
                    label: {
                      borderColor: '#B3F7CA',
                      style: {
                        fontSize: '10px',
                        color: '#fff',
                        background: '#00E396'
                      },
                      offsetY: -10,
                      text: 'X-axis range'
                    }
                  }
                ],
                points: [
                  {
                    x: new Date('01 Dec 2017').getTime(),
                    y: 8607.55,
                    marker: {
                      size: 8,
                      fillColor: '#fff',
                      strokeColor: 'red',
                      radius: 2,
                      cssClass: 'apexcharts-custom-class'
                    },
                    label: {
                      borderColor: '#FF4560',
                      offsetY: 0,
                      style: {
                        color: '#fff',
                        background: '#FF4560'
                      },

                      text: 'Point Annotation'
                    }
                  },
                  {
                    x: new Date('08 Dec 2017').getTime(),
                    y: 9340.85,
                    marker: {
                      size: 0
                    },
                    image: {
                      path: '../../assets/images/ico-instagram.png'
                    }
                  }
                ]
              },
              dataLabels: {
                enabled: false
              },
              stroke: {
                curve: 'straight'
              },
              grid: {
                padding: {
                  right: 30,
                  left: 20
                }
              },
              title: {
                text: 'CPU USAGE',
                align: 'left'
              },
              labels: [10, 20, 25, 31, 45, 52, 55, 60, 100],
              xaxis: {
                type: 'datetime'
              }
            }}
            series={[
              {
                name: 'CPU',
                data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
              }
            ]}
            type="area"
            width="350"
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default Experiment;

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">
          {'5'}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired
};

const ll = [
//   {
//     name: 'DEPLOY (LOCAL & CLOUD)'
//   },
  {
    name: 'INTERPRET THIS MODEL'
  },
  {
    name: 'DIAGONSE MODEL ON NEW DATASET'
  },
  {
    name: 'SCORE ON ANOTHER DATASET'
  },
  {
    name: 'TRANSFORM ANOTHER DATASET'
  },
  {
    name: 'DOWNLOAD PREDICTIONS'
  },
  {
    name: 'DOWNLOAD PYTHON SCORING PIPELINE'
  },

  {
    name: 'DOWNLOAD MOJO SCORING PIPELINE'
  },
  {
    name: 'DOWNLOAD EXPERIMENT SUMMARY'
  },
  {
    name: 'DOWNLOAD LOGS'
  },
  {
    name: 'DOWNLOAD AUTOREPORT'
  }
];
