import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, Icon, Tooltip } from '@mui/material';
export default function FormDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" size='small' onClick={handleClickOpen}>
        Help
      </Button>
      {/* <Tooltip title='help'>
      <Icon onClick={handleClickOpen}>help_outline</Icon>
      </Tooltip> */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Help</DialogTitle>
        <DialogContent>
          {/* <DialogContentText> */}
            {/* <Grid item xs={12} sm={12}> */}
            <h3>what do these settings mean?</h3>
            <h3>ACCURACY</h3>
            <ul>
              <li>Training data size:23.999 rows ,25 cols</li>
              <li>Feature evalution:[GLM, LightGBM, XGBoostGBM],1/3</li>
            </ul>
            <h3>Validation split</h3>
            <ul>
              <li>Final pipeline:Ensemble(4 models), 4-fold CV</li>
            </ul>
            <h3>TIME</h3>
            <ul>
              <li>Feature evalution:4 individuals,up to 56 iterations</li>
              <li>EARLY Stoping:After 5 iterations of no improvement</li>
            </ul>
            <h3>INTERPRETABILITY</h3>
            <ul>
              <li>Feature pre-prunning strategy:FS</li>
              <li>Manatonicity contrains:Disabled</li>
              <li>Feature engineering search space (where applicable)</li>
              <li>
                [CVCatnumcode,CVTargetEncode,Cat,Clusterid,ClusterTE,
                Text,WeightofEvidence]
              </li>
            </ul>
            <ul>
              <li>[LLM ,LightGBM, XGBoostBGM] model to train</li>
              <li>Model and feature tuning:16</li>
              <li>Feature evolution:B4</li>
              <li>Final pipeline:4</li>
            </ul>
            <ul>
              <li>Estimated runtime:Minutes</li>
              <li>Auto-click finish if not done in:1day</li>
            </ul>
            {/* </Grid> */}
          {/* </DialogContentText> */}
          {/* <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
          /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {/* <Button onClick={handleClose}>Subscribe</Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}
