import React from 'react';
import {
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Icon,
  TablePagination,
  Menu,
  MenuItem,
  TableContainer,
  Paper,
  Box,
  Button
  //Link
} from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  FilePlus as FilePluseIcon,
  BarChart as BarChartIcon,
  Upload as UploadIcon,
  PauseCircle as PauseCircleIcon,
  Database as DatabaseIcon,
  Sun as SunIcon,
  ZoomIn as ZoomInIcon,
  Codepen as CodepenIcon,
  Settings as SettingsIcon,
  HelpCircle as HelpCircleIcon,
  MoreVertical as MoreVerticalIcon
} from 'react-feather';
import Split from './dataspliter';
import Visualize from './visualization';
import Pedict from './predict'
import Breadcrumb from './Breadcrumb';
const List = [
  {
    Name: 'CreditCard-test.csv',
    Path: '18 january, 2019',
    Size: 1000,
    Rows: 'close',
    Column: 'ABC Fintech LTD.'
  },
  {
    Name: 'john doe',
    Path: '18 january, 2019',
    Size: 1000,
    Rows: 'close',
    Column: 'ABC Fintech LTD.'
  }
];

const H2o = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [anchorEls, setAnchorEls] = React.useState(null);

  const handleClicks = (event) => {
    setAnchorEls(event.currentTarget);
  };

  const handleCloses = () => {
    setAnchorEls(null);
  };
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
  };

  return (
    <Box
      sx={{
        p: 4
      }}
    >
      <Breadcrumb
        routeSegments={[
          { name: 'Data', path: '/ai/datasets' },
          //{ name: 'Autocomplete' }
        ]}
      />

      <Box
        sx={{
          mt: 1,
          p: 1,
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button
          variant="contained"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          + ADD DATASET OR (DRAG & DROP)
        </Button>
        <Menu
          id="simple-menu"
          style={{ marginTop: '44px' }}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>
            <FilePluseIcon /> &nbsp;FILE SYSTEM
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <UploadIcon /> &nbsp;UPLOAD FILE
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <DatabaseIcon />
            &nbsp;AMAZON S3
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <BarChartIcon />
            &nbsp;HADOOP FILE SYSTEM
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <ZoomInIcon />
            &nbsp;GOOGLE BIG QUERY
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <PauseCircleIcon />
            &nbsp;GOOGLE CLOUD STORAGE
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <FilePluseIcon />
            &nbsp;KDB+
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <SunIcon />
            &nbsp;MINO
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <CodepenIcon />
            &nbsp;SNOWFLAKE
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <FilePluseIcon />
            &nbsp;JDBC
          </MenuItem>
        </Menu>
      </Box>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Path</TableCell>
              <TableCell>Size</TableCell>
              <TableCell>Rows</TableCell>
              <TableCell>Column</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {List.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            ).map((res, index) => (
              <TableRow key={index}>
                <TableCell align="left">{res.Name}</TableCell>
                <TableCell align="left">{res.Path}</TableCell>
                <TableCell align="left">{res.Size}</TableCell>
                <TableCell>{res.Rows}</TableCell>
                <TableCell>{res.Column}</TableCell>
                <TableCell
                  aria-controls="simple-menua"
                  aria-haspopup="true"
                  onClick={handleClicks}
                >
                  <MoreVerticalIcon />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Menu
          id="simple-menua"
          style={{ marginTop: '44px' }}
          anchorEl={anchorEls}
          keepMounted
          open={Boolean(anchorEls)}
          onClose={handleCloses}
        >
          <MenuItem onClick={handleCloses}>DETAILS</MenuItem>
          <Visualize />
          {/* <MenuItem onClick={handleCloses}>VISUALIZE</MenuItem> */}
          {/* <MenuItem onClick={handleCloses}>SPLIT</MenuItem> */}
          <Split />
          {/* <MenuItem>
            <RouterLink to="/ai/predict">PREDICT</RouterLink>
          </MenuItem> */}
          <Pedict/>
          <MenuItem onClick={handleCloses}>DOWNLOAD</MenuItem>
          <MenuItem onClick={handleCloses}>DELETE</MenuItem>
        </Menu>
        <TablePagination
          className="px-4"
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={List.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page'
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page'
          }}
          //onChangePage={handleChangePage}
          // onChangeRowsPerPage={handleChangeRowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          //ActionsComponent={TablePaginationActions}
        />
      </TableContainer>
    </Box>
  );
};

export default H2o;
