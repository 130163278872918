import React, { useState } from 'react';
import {
  Box,
  Card,
  Stack,
  CardContent,
  CardMedia,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
  TextField,
  Icon,
  Dialog,
  DialogActions,
  Paper,
  Container
} from '@mui/material';
import RichTextEditor from './editor/RichTextEditor';
//import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Breadcrumb from './Breadcrumb';
import { CardActionArea, Typography } from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
const useStyles = makeStyles({
  imm: {
    background: 'rgba(135,206,250,.5)',
    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    '&:hover': {
      backgroundColor: 'rgba(135,206,250,.5)'
      // boxShadow: '0 3px 5px 2px black',
    }
    // '&$selected': {
    //   backgroundColor: 'rgba(135,206,250,.5)'
    // }
  },
  vpaper: {
    background: 'red',
    backgroundColor: 'red !important'
    //boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)'
  }
});
const ViewProject = (props) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  //let navigate = useNavigate();
  // const [secondli, setsecondli] = useState('');
  const [select, setselect] = useState(2);
  // const handleClickOpen = (res) => {
  //   res.id == 1 ? setOpen(true) : setOpen(false);
  //   res.id == 1 ? '' : setview(!view);
  //   res.id == 1 ? '' : setsecondli(res);
  //  // console.log(res);
  // };
  const handoo = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handlepaper = (id) => {
    setselect(id.id);
    id.id == 11 ? handleClickOpens() : '';
    //console.log(id);
  };
  const [opens, setOpens] = React.useState(false);

  const handleClickOpens = () => {
    setOpens(true);
  };

  const handleCloses = () => {
    setOpens(false);
  };
  const [content, setContent] = useState(`start editing here`);

  // const [filt, setfilt] = React.useState(list);
  // React.useEffect(() => {
  //   filtering();
  // }, []);

  // const filtering = () => {
  //   var data = list.filter(function (el) {
  //     if (el.id == window.location.pathname.split('/')[2]) {
  //       return filt;
  //     }
  //   });
  //   setfilt(data);
  //   // console.log(data);
  // };
  // const [lll, setlll] = React.useState(lists);
  // React.useEffect(() => {
  //   window.location.pathname.split('/')[2] == 8
  //     ? setlll([...lll, ...readme])
  //     : window.location.pathname.split('/')[2] == 2
  //     ? setlll([...lll, ...Datapipelines])
  //     : window.location.pathname.split('/')[2] == 3
  //     ? setlll([...lll, ...financemodel])
  //     : window.location.pathname.split('/')[2] == 4
  //     ? setlll([...lll, ...healthcare])
  //     : window.location.pathname.split('/')[2] == 5
  //     ? setlll([...lll, ...realestate])
  //     : '';
  // }, []);
  // const [sha, setsha] = useState(1);
  // const onMouseOver = () => setsha(8);
  // const onMouseOut = () => setsha(1);
  // const [view, setview] = useState(false);
  // const roww = () => {
  //  // console.log('clovl', view);
  //   setview(!view);
  // };
  return (
    <Box
      sx={{
        p: 0
      }}
    >
      {/* <Breadcrumb
        routeSegments={[
          { name: 'Projects', path: '/ai/projects' },
          { name: 'Projects' }
        ]}
      />
      <br /> */}
      {/* <h4>
        Resources ( <span style={{ color: '#0055B4' }}>{filt[0].name}</span> )
      </h4> */}

      {/* <Fab  size='small' aria-label="add" onClick={handoo}>
        <AddIcon onClick={handoo}/>
      </Fab> */}
     
      {window.location.pathname.split('/')[2] == 2 ? (
        <Box sx={{ p: 4, display: 'flex', justifyContent: 'flex-end' }}>
          <Fab size="small" color="primary" aria-label="add" onClick={handoo}>
            <AddIcon onClick={handoo} />
          </Fab>
        </Box>
      ) : (
        <AddCircleOutlineIcon onClick={handoo} />
      )}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle style={{ textAlign: 'center', alignItems: 'center' }}>
          Add new Resource(Healthcare Models)
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            {newlist.map((res, index) => (
              <Grid item xs={6} sm={3} md={2} key={index}>
                <Paper
                  sx={{ height: 140, width: 140 }}
                  className={classes.imm}
                  onClick={() => handlepaper(res)}
                >
                  <div style={{ textAlign: 'center', alignItems: 'center' }}>
                    <Icon style={{ fontSize: '90px' }} color="inherit">
                      {res.img}
                    </Icon>
                  </div>

                  <div style={{ textAlign: 'center' }}>{res.name}</div>
                </Paper>
              </Grid>
            ))}
          </Grid>
          {/*  */}
          {select == 1 ? (
            <Container maxWidth="sm">
              <Formik
                initialValues={{
                  firstName: ''
                }}
                validationSchema={Yup.object().shape({
                  firstName: Yup.string()
                    .max(255)
                    .required('First name is required')
                })}
                onSubmit={() => {}}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
                }) => (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      size="small"
                      error={Boolean(touched.firstName && errors.firstName)}
                      fullWidth
                      helperText={touched.firstName && errors.firstName}
                      label="Regression
                  Pipeline"
                      margin="normal"
                      name="firstName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.firstName}
                      //variant="outlined"
                    />

                    <Box sx={{ py: 1 }}>
                      <Button
                        // color="primary"
                        disabled={isSubmitting}
                        //fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        submit
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </Container>
          ) : select == 2 ? (
            <Container maxWidth="sm">
              <Formik
                initialValues={{
                  Pipeline: ''
                }}
                validationSchema={Yup.object().shape({
                  Pipeline: Yup.string()
                    .max(255)
                    .required('Pipeline name is required')
                })}
                onSubmit={() => {}}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
                }) => (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      size="small"
                      error={Boolean(touched.Pipeline && errors.Pipeline)}
                      fullWidth
                      helperText={touched.Pipeline && errors.Pipeline}
                      label="Classification Pipeline"
                      margin="normal"
                      name="Pipeline"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.Pipeline}
                      //variant="outlined"
                    />

                    <Box sx={{ py: 1 }}>
                      <Button
                        // color="primary"
                        disabled={isSubmitting}
                        //fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        submit
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </Container>
          ) : select == 3 ? (
            <Container maxWidth="sm">
              <Formik
                initialValues={{
                  CPipeline: ''
                }}
                validationSchema={Yup.object().shape({
                  CPipeline: Yup.string()
                    .max(255)
                    .required('CPipeline name is required')
                })}
                onSubmit={() => {}}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
                }) => (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      size="small"
                      error={Boolean(touched.CPipeline && errors.CPipeline)}
                      fullWidth
                      helperText={touched.CPipeline && errors.CPipeline}
                      label="Clustering Pipeline "
                      margin="normal"
                      name="CPipeline"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.CPipeline}
                      //variant="outlined"
                    />

                    <Box sx={{ py: 1 }}>
                      <Button
                        // color="primary"
                        disabled={isSubmitting}
                        //fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        submit
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </Container>
          ) : select == 4 ? (
            <Container maxWidth="sm">
              <Formik
                initialValues={{
                  TimePipeline: ''
                }}
                validationSchema={Yup.object().shape({
                  TimePipeline: Yup.string()
                    .max(255)
                    .required('Time Series  Pipeline name is required')
                })}
                onSubmit={() => {}}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
                }) => (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      size="small"
                      error={Boolean(
                        touched.TimePipeline && errors.TimePipeline
                      )}
                      fullWidth
                      helperText={touched.TimePipeline && errors.TimePipeline}
                      label="Time Series Pipeline"
                      margin="normal"
                      name="TimePipeline"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.TimePipeline}
                      //variant="outlined"
                    />

                    <Box sx={{ py: 1 }}>
                      <Button
                        // color="primary"
                        disabled={isSubmitting}
                        //fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        submit
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </Container>
          ) : select == 5 ? (
            <Container maxWidth="sm">
              <Formik
                initialValues={{
                  RecommendationPipeline: ''
                }}
                validationSchema={Yup.object().shape({
                  RecommendationPipeline: Yup.string()
                    .max(255)
                    .required('Recommendation engine Pipeline name is required')
                })}
                onSubmit={() => {}}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
                }) => (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      size="small"
                      error={Boolean(
                        touched.RecommendationPipeline &&
                          errors.RecommendationPipeline
                      )}
                      fullWidth
                      helperText={
                        touched.RecommendationPipeline &&
                        errors.RecommendationPipeline
                      }
                      label="Recommendation engine
                      Pipeline"
                      margin="normal"
                      name="RecommendationPipeline"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.RecommendationPipeline}
                      //variant="outlined"
                    />

                    <Box sx={{ py: 1 }}>
                      <Button
                        // color="primary"
                        disabled={isSubmitting}
                        //fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        submit
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </Container>
          ) : select == 6 ? (
            <Container maxWidth="sm">
              <Formik
                initialValues={{
                  Manualml: ''
                }}
                validationSchema={Yup.object().shape({
                  Manualml: Yup.string()
                    .max(255)
                    .required('Manual ML Pipeline name is required')
                })}
                onSubmit={() => {}}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
                }) => (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      size="small"
                      error={Boolean(touched.Manualml && errors.Manualml)}
                      fullWidth
                      helperText={touched.Manualml && errors.Manualml}
                      label="Manual ML
                      Pipeline"
                      margin="normal"
                      name="Manualml"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.Manualml}
                      //variant="outlined"
                    />

                    <Box sx={{ py: 1 }}>
                      <Button
                        // color="primary"
                        disabled={isSubmitting}
                        //fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        submit
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </Container>
          ) : select == 7 ? (
            <Container maxWidth="sm">
              <Formik
                initialValues={{
                  DataPipeline: ''
                }}
                validationSchema={Yup.object().shape({
                  DataPipeline: Yup.string()
                    .max(255)
                    .required('Data resource catlog name is required')
                })}
                onSubmit={() => {}}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
                }) => (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      size="small"
                      error={Boolean(
                        touched.DataPipeline && errors.DataPipeline
                      )}
                      fullWidth
                      helperText={touched.DataPipeline && errors.DataPipeline}
                      label="Data resource catlog name"
                      margin="normal"
                      name="DataPipeline"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.DataPipeline}
                      //variant="outlined"
                    />

                    <Box sx={{ py: 1 }}>
                      <Button
                        // color="primary"
                        disabled={isSubmitting}
                        //fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        submit
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </Container>
          ) : select == 8 ? (
            <Container maxWidth="sm">
              <Formik
                initialValues={{
                  PredictionPipeline: ''
                }}
                validationSchema={Yup.object().shape({
                  PredictionPipeline: Yup.string()
                    .max(255)
                    .required('Prediction resource  name is required')
                })}
                onSubmit={() => {}}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
                }) => (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      size="small"
                      error={Boolean(
                        touched.PredictionPipeline && errors.PredictionPipeline
                      )}
                      fullWidth
                      helperText={
                        touched.PredictionPipeline && errors.PredictionPipeline
                      }
                      label="Prediction resource  name"
                      margin="normal"
                      name="PredictionPipeline"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.PredictionPipeline}
                      //variant="outlined"
                    />

                    <Box sx={{ py: 1 }}>
                      <Button
                        // color="primary"
                        disabled={isSubmitting}
                        //fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        submit
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </Container>
          ) : select == 9 ? (
            <Container maxWidth="sm">
              <Formik
                initialValues={{
                  folder: ''
                }}
                validationSchema={Yup.object().shape({
                  folder: Yup.string()
                    .max(255)
                    .required('Folder name is required')
                })}
                onSubmit={() => {}}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
                }) => (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      size="small"
                      error={Boolean(touched.folder && errors.folder)}
                      fullWidth
                      helperText={touched.folder && errors.v}
                      label="Folder name"
                      margin="normal"
                      name="folder"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.folder}
                      //variant="outlined"
                    />

                    <Box sx={{ py: 1 }}>
                      <Button
                        // color="primary"
                        disabled={isSubmitting}
                        //fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        submit
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </Container>
          ) : select == 11 ? (
            <Dialog open={opens} onClose={handleCloses} fullWidth maxWidth="md">
              <DialogTitle
                style={{ textAlign: 'center', alignItems: 'center' }}
              >
                Note
              </DialogTitle>
              <DialogContent>
                <RichTextEditor
                  content={content}
                  handleContentChange={(content) => setContent(content)}
                  placeholder="insert text here..."
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloses}>Cancel</Button>
              </DialogActions>
            </Dialog>
          ) : (
            ''
          )}
          {/*  */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      {/* {!view ? (
        <Grid container spacing={1}>
          {lll.map((res, index) => (
            <Grid item xs={6} sm={3} md={2} key={index}>
              <Paper
                // elevation={sha}
                // onMouseOver={onMouseOver}
                // onMouseOut={onMouseOut}
                sx={{ minHeight: 180 }}
                key={index}
                //style={{ margin: '10px' }}
                onClick={() => handleClickOpen(res)}
              >
              
                <div style={{ textAlign: 'center', alignItems: 'center' }}>
                  <Icon style={{ fontSize: '100px' }} color="inherit">
                    {res.img}
                  </Icon>
                </div>
                <CardContent>
                  <Typography
                    style={{ textAlign: 'center' }}
                    variant="body2"
                    color="text.secondary"
                  >
                    {res.name}
                  </Typography>
                </CardContent>
              
              </Paper>
            </Grid>
          ))}
        </Grid>
      ) : (
        <div>
         
          <p onClick={roww}>
            <span style={{ color: '#0055B4' }}>{filt[0].name}</span>/
            {secondli.name}
          </p>
          <br />
          <Grid container spacing={1}>
            {lists.map((res, index) => (
              <Grid item xs={6} sm={3} md={2} key={index}>
                <Paper
                  sx={{ minHeight: 180 }}
                  // key={index}

                  onClick={() => handleClickOpen(res)}
                >
                
                  <div style={{ textAlign: 'center', alignItems: 'center' }}>
                    <Icon style={{ fontSize: '100px' }} color="inherit">
                      {res.img}
                    </Icon>
                  </div>
                  <CardContent>
                    <Typography
                      style={{ textAlign: 'center' }}
                      variant="body2"
                      color="text.secondary"
                    >
                      {res.name}
                    </Typography>
                  </CardContent>
                  
                </Paper>
              </Grid>
            ))}
          </Grid> 
        </div>
      )}*/}
      
    </Box>
  );
};
export default ViewProject;
const lists = [
  {
    name: '',
    id: '1',
    img: 'add_circle_outline_icon'
  }
  // {
  //   name: 'corona virus app',
  //   id: '8',
  //   img: 'data_usage'
  // },
  // {
  //   name: 'coronapatient.csv',
  //   id: '2',
  //   img: 'upload_file'
  // },
  // {
  //   name: 'coronatest.csv',
  //   id: '3',
  //   img: 'upload_file'
  // }
];

const newlist = [
  {
    name: 'regression Pipeline',
    id: '1',
    img: 'margin'
  },
  {
    name: 'Classification Pipeline',
    id: '2',
    img: 'lens_blur'
  },
  {
    name: 'Clustering Pipeline',
    id: '3',
    img: 'flip_camera_android_icon'
  },
  {
    name: 'Time Series Pipeline',
    id: '4',
    img: 'auto_graph'
  },
  {
    name: 'Recommendation Pipeline',
    id: '5',
    img: 'add_task'
  },
  {
    name: 'Manual ML Pipeline',
    id: '6',
    img: 'biotech'
  },
  {
    name: 'Data Pipeline',
    id: '7',
    img: 'storage_outlined_icon'
  },
  {
    name: 'Prediction Pipeline',
    id: '8',
    img: 'lightbulb_outlined_icon'
  },
  {
    name: 'Folder',
    id: '9',
    img: 'folder_open'
  },
  {
    name: 'File',
    id: '10',
    img: 'upload_file'
  },
  {
    name: 'Notes',
    id: '11',
    img: 'note_add_outlined_icon'
  },
  {
    name: 'Web URL Shortcut',
    id: '12',
    img: 'language'
  }
];
const list = [
  {
    name: '',
    id: '1',
    img: 'add_circle_outline_icon'
  },
  {
    name: 'Readme first',
    id: '8',
    img: 'settings'
  },
  {
    name: 'Data pipelines',
    id: '2',
    img: 'settings'
  },
  {
    name: 'Finance models',
    id: '3',
    img: 'settings'
  },
  {
    name: 'Healthcare Models',
    id: '4',
    img: 'settings'
  },
  {
    name: 'Resource models',
    id: '5',
    img: 'settings'
  },
  {
    name: 'Readme first',
    id: '6',
    img: 'settings'
  },
  {
    name: 'Readme first',
    id: '7',
    img: 'settings'
  }
];

const readme = [
  {
    name: 'Test.csv',
    img: 'picture_as_pdf'
  },

  {
    name: '0.0 Hitch-hikers guide to ML and Numtra (V 1.3).pdf',
    img: 'picture_as_pdf'
  },

  {
    name: '5.0 Self-Service ML with Numtra.pdf',
    img: 'picture_as_pdf'
  },

  {
    name: '4.0 ML Use Cases by Industry.pdf',
    img: 'picture_as_pdf'
  },

  {
    name: '3.0 Types of ML.pdf',
    img: 'picture_as_pdf'
  },

  {
    name: '2.0 A Simple ML Case Study.pdf',
    img: 'picture_as_pdf'
  },

  {
    name: '1.0 An Intro to AI & ML .pdf',
    img: 'picture_as_pdf'
  }
];
const Datapipelines = [
  {
    name: 'Classification',
    img: 'folder'
  },
  {
    name: 'Regression',
    img: 'folder'
  },
  {
    name: 'clustering',
    img: 'folder'
  }
];
const financemodel = [
  {
    name: 'Life Insurance Risk Assessment ',

    img: 'folder'
  },
  {
    name: 'Santander Product Recommendation - Regression',

    img: 'folder'
  },
  {
    name: 'Credit Card Fraud Detection - Regression ',

    img: 'folder'
  },
  {
    name: 'Loan Pay-off Prediction - Classification ',

    img: 'folder'
  },
  {
    name: 'Sales Forecasting - Time Series ',

    img: 'folder'
  },
  {
    name: 'Financial Customer Segmentation - Clustering ',

    img: 'folder'
  },
  {
    name: 'New York Stock Exchange - Regression ',

    img: 'folder'
  },
  {
    name: 'Bank Deposit-Classification',

    img: 'folder'
  },
  {
    name: 'Predict Highest Income-Regression',

    img: 'folder'
  },
  {
    name: 'Increase Customer Acquisition - Classification',

    img: 'folder'
  },
  {
    name: 'Loan Repayments Model - Regression',

    img: 'folder'
  },
  {
    name: 'Marketing Targets for Banks - Classification',

    img: 'folder'
  },
  {
    name: 'Risk Based Interest Pricing - Classification',

    img: 'folder'
  }
];
const healthcare = [
  {
    name: 'Hospital LOS',

    img: 'folder'
  },
  {
    name: 'Fetal Health Classification ',

    img: 'folder'
  },
  {
    name: 'Heart Failure Prediction Model',

    img: 'folder'
  },
  {
    name: 'Healthcare Expenditure Time Series ',

    img: 'folder'
  },
  {
    name: 'Patient Charges Clustering Model',

    img: 'folder'
  },
  {
    name: 'Patient Charges - Regression',

    img: 'folder'
  },
  {
    name: 'Corona Virus App',

    img: 'folder'
  },
  {
    name: 'Appointment No-Show Prediction - Regression',

    img: 'folder'
  },
  {
    name: 'Claims Denial Propensity Scoring',

    img: 'folder'
  },
  {
    name: 'RCM - Claims Management - Classification',

    img: 'folder'
  },
  {
    name: 'ABCDADADA',

    img: 'prediction'
  },
  {
    name: 'LOS-b',

    img: 'prediction'
  }
];
const realestate = [
  {
    name: 'Zillow Rent Index - Regression ',

    img: 'folder'
  },
  {
    name: 'Real Estate Price Prediction - Regression ',

    img: 'folder'
  },
  {
    name: 'Boston Housing App ',

    img: 'folder'
  }
];
