import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {MenuItem,Grid} from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import Bubble from './chart/bubble';
import Grouped from './chart/grouped';
import Linechart from './chart/linechart';
import Pie from './chart/pie';
import Radar from './chart/radar';
import Verticalbar from './chart/verticalbar'
//import Polar from './chart/polar'
export default function Visualization() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <MenuItem  onClick={handleClickOpen}>
      VISUALIZE
      </MenuItem>
      <Dialog open={open}
      fullWidth
      maxWidth={'md'}
      onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">VISUALIZATION</DialogTitle>
        <DialogContent>
      <Grid container spacing={1}>
          <Grid item xs={12} sm={4}>
          <Bubble/>
          </Grid>
          <Grid item xs={12} sm={4}>
          <Grouped/>
          </Grid>
          <Grid item xs={12} sm={4}>
          <Linechart/> 
          </Grid>
          <Grid item xs={12} sm={4}>
          <Pie/>
          </Grid>
          <Grid item xs={12} sm={4}>
          <Verticalbar/>
          </Grid>
          <Grid item xs={12} sm={4}>
          <Radar/> 
          </Grid>
      </Grid>
   
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          {/* <Button onClick={handleClose} color="primary">
            Subscribe
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}
