import React, { Component } from 'react';
import FHIR from 'fhirclient';
import { fhirauth_setSmart } from '../Redux/actions/authorize';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';
const AfterAuth = (props) => {
  let navigate = useNavigate();
  React.useEffect(() => {
    let fhirClient = null;
    // window.FHIR = FHIR;
    FHIR.oauth2
      .ready()
      .then((client) => {
        client.request('metadata')
        fhirClient = client;
        window.fhirClient = client;
        props.fhirauth_setSmart(client);
        console.log(client, window);
      })
      .catch((err) => {
        console.log(err)
        window.location.href = '/';
      });
  }, []);
  React.useEffect(() => {
    props.user.sbmUserId && navigate('/ai/datasets');
  }, [props.user]);
  return <div></div>;
};

const mapStateToProps = (state) => {
  return {
    user: state.user.oauthUser
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ fhirauth_setSmart }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AfterAuth);
