import { combineReducers } from 'redux';
import authorize from './authorize';
import user from './user';

const rootReducer = combineReducers({
  authorize,
  user
});

export default rootReducer;
