import React, { useState } from 'react';
import {
  Grid,
  TextField,
  CircularProgress,
  Button,
  Box,
  Typography,
  Divider,
  DialogActions,
  Paper,
  MenuItem
} from '@mui/material';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { Icon, StepLabel, Step, Stepper, Container } from '@mui/material';
import PropTypes from 'prop-types';
import Expertsettings from './expertsetting';
import HelpDialog from './helpdialog';
import ExperimentLaunch from './exprementslaunch';
import Experiment from './experiment';
import ExSummary from './exsummary';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const Predict = (props) => {
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [expand, setexpand] = useState(true);
  const exx = () => {
    setexpand(!expand);
  };
  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return expand ? (
          <form noValidate autoComplete="off">
            {/* <ExpandLessIcon onClick={exx} /> */}
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="stretch"
            >
              <Grid item xs={12} sm={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={11}>
                    <DialogActions>
                      <HelpDialog />
                      {/* <Button>TRAINING SETTING</Button> &nbsp; <Expertsettings /> */}
                    </DialogActions>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="DISPLAY NAME"
                      defaultValue="DISPLAY NAME"
                      variant="outlined"
                      size="small"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="DATASET"
                      defaultValue="CreditCard-train.csv"
                      variant="outlined"
                      size="small"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      label="ROWS"
                      defaultValue="24K"
                      variant="outlined"
                      size="small"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      label="COLUMNS"
                      defaultValue="25"
                      variant="outlined"
                      size="small"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      label="DROPPED COLUMN"
                      defaultValue=""
                      variant="outlined"
                      size="small"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      label="VALIDATION DATASET"
                      defaultValue=""
                      variant="outlined"
                      size="small"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      label="TEST DATASET"
                      defaultValue=""
                      variant="outlined"
                      size="small"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="TARGET COLUMN"
                      defaultValue="Default payment next"
                      variant="outlined"
                      size="small"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="FOLD COLUMN"
                      defaultValue=""
                      variant="outlined"
                      size="small"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="WEIGHT COLUMN"
                      defaultValue=""
                      variant="outlined"
                      size="small"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="TIME COLUMN"
                      defaultValue="[OFF]"
                      variant="outlined"
                      size="small"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <p>TYPE</p>
                    <h3>Bool</h3>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <p>count</p>
                    <h3>23999</h3>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <p>UNIQUE</p>
                    <h3>3</h3>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <p>TARGET FREQ</p>
                    <h3>5369</h3>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <CircularProgressWithLabel
                      variant="determinate"
                      value={100}
                    >
                      <p>5</p>
                    </CircularProgressWithLabel>
                    <p>ACCURACY</p>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <CircularProgressWithLabel
                      variant="determinate"
                      value={100}
                    >
                      <p>4</p>
                    </CircularProgressWithLabel>
                    <p>TIME</p>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <CircularProgressWithLabel
                      variant="determinate"
                      value={100}
                    >
                      <p>6</p>
                    </CircularProgressWithLabel>
                    <p>INTERPETABLITY</p>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <CircularProgressWithLabel
                      variant="determinate"
                      value={100}
                    >
                      <p>AUC</p>
                    </CircularProgressWithLabel>
                    <p>SCORER</p>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Button variant="outlined">CLASSIFICATION</Button>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Button variant="outlined">REPRODUCIBLE</Button>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Button variant="outlined">GPUS DISABLED</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <br />
            <Button variant="outlined" onClick={exx}>
              Expert Setting
            </Button>
          </form>
        ) : (
          <div>
            <Button variant="outlined" onClick={exx}>
              Back
            </Button>
            {/* <ExpandMoreIcon onClick={exx} /> */}
            <Expertsettings />
          </div>
        );
      // case 1:
      //   return (
      //     <div>
      //       <DialogActions>
      //         <HelpDialog />
      //         {/* <Button>TRAINING SETTING</Button> &nbsp; <Expertsettings /> */}
      //       </DialogActions>
      //     </div>
      //   );
      case 1:
        return (
          <div>
            <div style={{ float: 'right' }}>
              <HelpDialog />
            </div>
            <ExSummary />;
          </div>
        );
      // case 2:
      //   return <ExperimentLaunch />;
      default:
        return '';
    }
  }
  return (
    // <Box
    //   sx={{
    //     p: 4
    //     //backgroundColor:'white'
    //   }}
    // >

    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open form dialog
      </Button> */}
      {window.location.pathname == '/ai/datasets' ? (
        <MenuItem onClick={handleClickOpen}>PREDICT</MenuItem>
      ) : (
        <Button
          variant="contained"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClickOpen}
        >
          + NEW EXPERIMENT
        </Button>
      )}
      <Dialog open={open} fullWidth maxWidth="lg" onClose={handleClose}>
        <DialogTitle style={{ textAlign: 'center' }}>Predict</DialogTitle>
        <DialogContent>
          {/* <Paper style={{ padding: '5px' }}> */}
          <Stepper activeStep={activeStep} alternativeLabel variant="dots">
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <br />
          <div>
            {/* <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            > */}
            {activeStep === steps.length ? (
              <div>
                <div style={{ textAlign: 'center' }}>
                  <Button href="/ai/experiment" variant="outlined">
                    launch experiment
                  </Button>
                  <br />
                  <Icon>done</Icon> <span className="ml-2">Done</span>
                  &nbsp;
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleReset}
                    size="small"
                  >
                    Reset
                  </Button>
                </div>
              </div>
            ) : (
              <div>
                {getStepContent(activeStep)}
                <br />
                <div
                  style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    textAlign: 'center'
                  }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                  >
                    Back
                  </Button>
                  &nbsp;
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                  >
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </div>
              </div>
            )}
            {/* </Grid> */}
          </div>
          {/* </Paper> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {/* <Button onClick={handleClose}>Subscribe</Button> */}
        </DialogActions>
      </Dialog>
    </div>

    // </Box>
  );
};
export default Predict;
function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">
          {'5'}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired
};

const getSteps = () => {
  return ['Datasets', 'Summary'];
};
