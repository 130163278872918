import React from 'react';
import {
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Icon,
  TablePagination,
  Link,
  TableContainer,
  Paper,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Breadcrumb from './Breadcrumb';
import Stack from '@mui/material/Stack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Experiment from './predict';
import { useNavigate } from 'react-router-dom';
const ExperimentsPage = () => {
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const navigate = useNavigate();
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClick = (res) => {
    console.log(res);
    // <RouterLink to="/app/experimentlaunch" />;
  };

  return (
    <Box
      sx={{
        p: 4
      }}
    >
      <Breadcrumb
        routeSegments={[
          { name: 'Experiments', path: 'ai/experiments' }
          //{ name: 'Autocomplete' }
        ]}
      />
      <Box
        sx={{
          mt: 1,
          p: 1,
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Experiment />
        {/* <Button
          variant="contained"
          aria-controls="simple-menu"
          aria-haspopup="true"
         
        >
          + NEW EXPERIMENT
        </Button> */}
      </Box>
      {List.map((res, index) => (
        <Accordion disableGutters key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
           // sx={{backgroundColor:'#f5f5f5'}}
          >
            {/* <Stack spacing={2} direction="row">
           
            </Stack> */}
            {/* <table style={{display:'table',width:'100%'}} >
              <thead>
                <tr>
                  <th>Firstname</th>
                  <th>Lastname</th>
                  <th>Age</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>ghhghnjhfghjjty</td>
                  <td>yyjfgjy</td>
                  <td>yytytj</td>
                </tr>
              </tbody>
            </table> */}
            <Table size="small" key={index} sx={{ width: '100%', flexShrink: 10 }}>
              <TableHead>
                <TableRow
                  style={{
                    borderBottom: '0px solid rgba(224, 224, 224, 1)',
                    padding: '0px',
                    width:'20%',
                    maxWidth:'20%'
                  }}
                >
                  <TableCell
                    style={{
                      borderBottom: '0px solid rgba(224, 224, 224, 1)',
                      padding: '0px',
                      width:'20%',
                      maxWidth:'20%'
                    }}
                  >
                    Project
                  </TableCell>
                  <TableCell
                    style={{
                      borderBottom: '0px solid rgba(224, 224, 224, 1)',
                      padding: '0px',
                      width:'20%',
                      maxWidth:'20%'
                    }}
                  >
                    Pipeline
                  </TableCell>
                  <TableCell
                    style={{
                      borderBottom: '0px solid rgba(224, 224, 224, 1)',
                      padding: '0px',
                      width:'20%',
                      maxWidth:'20%'
                    }}
                  >
                    Owner
                  </TableCell>
                  <TableCell
                    style={{
                      borderBottom: '0px solid rgba(224, 224, 224, 1)',
                      padding: '0px',
                      width:'20%',
                      maxWidth:'20%'
                    }}
                  >
                    Type
                  </TableCell>
                  <TableCell
                    style={{
                      borderBottom: '0px solid rgba(224, 224, 224, 1)',
                      padding: '0px',
                      width:'20%',
                      maxWidth:'20%'
                    }}
                  >
                    description
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {res.li.map((res, index) => (
                  <TableRow key={index}>
                    <TableCell
                      style={{
                        borderBottom: '0px solid rgba(224, 224, 224, 1)',
                        padding: '0px'
                      }}
                    >
                      {res.project}
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: '0px solid rgba(224, 224, 224, 1)',
                        padding: '0px'
                      }}
                    >
                      {res.pipeline}
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: '0px solid rgba(224, 224, 224, 1)',
                        padding: '0px'
                      }}
                    >
                      {res.owner}
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: '0px solid rgba(224, 224, 224, 1)',
                        padding: '0px'
                      }}
                    >
                      {res.type}
                    </TableCell>

                    <TableCell
                      style={{
                        borderBottom: '0px solid rgba(224, 224, 224, 1)',
                        padding: '0px'
                      }}
                    >
                      {res.description}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper} sx={{backgroundColor:'#f5f5f5'}}>
              <Table size="small" key={index}>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Target</TableCell>
                    <TableCell>Dataset</TableCell>
                    <TableCell>Acc</TableCell>
                    <TableCell>Time</TableCell>
                    <TableCell>int</TableCell>
                    <TableCell>size</TableCell>
                    <TableCell>scorer</TableCell>
                    <TableCell>val. score</TableCell>
                    <TableCell>test score</TableCell>
                    <TableCell>status</TableCell>
                    <TableCell>ETA/Runtime</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(
            (res, index) => ( */}
                  <TableRow
                    key={index}
                    hover
                    // onClick={() =>
                    //   (window.location.href = '/ai/experimentlaunch')
                    // }
                    onClick={() =>
                      navigate('/ai/experimentlaunch', { replace: true })
                    }
                  >
                    <TableCell >{res.name}</TableCell>
                    <TableCell >{res.target}</TableCell>
                    <TableCell>{res.dataset}</TableCell>
                    <TableCell>{res.acc}</TableCell>
                    <TableCell>{res.time}</TableCell>
                    <TableCell>{res.int}</TableCell>
                    <TableCell>{res.size}</TableCell>
                    <TableCell>{res.scorer}</TableCell>

                    <TableCell>{res.valscore}</TableCell>
                    <TableCell>{res.testscore}</TableCell>
                    <TableCell>{res.status}</TableCell>
                    <TableCell>{res.eta}</TableCell>
                    <TableCell><Icon>launch</Icon></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      ))}
      {/* </Accordion>
      // <TablePagination
      //   className="px-4"
      //   rowsPerPageOptions={[5, 10, 25]}
      //   component="div"
      //   count={List.length}
      //   rowsPerPage={rowsPerPage}
      //   page={page}
      //   backIconButtonProps={{
      //     'aria-label': 'Previous Page'
      //   }}
      //   nextIconButtonProps={{
      //     'aria-label': 'Next Page'
      //   }}
      //   onPageChange={handleChangePage}
      //   onRowsPerPageChange={handleChangeRowsPerPage}
      //   // onChangePage={handleChangePage}
      //   // onChangeRowsPerPage={handleChangeRowsPerPage}
      // /> */}
    </Box>
  );
};

export default ExperimentsPage;
const List = [
  {
    name: 'demo video',
    target: 'default payment',
    dataset: 'creditcard transcation',
    acc: '5',
    time: '4',
    int: '6',
    size: '20mb',
    scorer: 'acc',
    valscore: '0.7793',
    testscore: '0.80011',
    status: 'completed',
    eta: '00:02:16',
    li: [
      {
        project: 'healthcare model',
        pipeline: 'healthcare',
        owner: 'venkat',
        type: 'Regression-auto',
        description: '..'
      }
    ]
  },
  {
    name: 'demo video',
    target: 'default payment',
    dataset: 'creditcard transcation',
    acc: '5',
    time: '4',
    int: '6',
    size: '200mb',
    scorer: 'acc',
    valscore: '0.77r93',
    testscore: '0.830011',
    status: 'completed',
    eta: '00:04:16',
    li: [
      {
        project: 'healthcare model',
        pipeline: 'hospital los',
        owner: 'venkat',
        type: 'Time-series-auto',
        description: '...'
      }
    ]
  },
  {
    name: 'demo video',
    target: 'default payment',
    dataset: 'creditcard transcation',
    acc: '7',
    time: '4',
    int: '67',
    size: '200mb',
    scorer: 'acc',
    valscore: '0.77993',
    testscore: '0.80011',
    status: 'completed',
    eta: '00:01:16',
    li: [
      {
        project: 'healthcare model',
        pipeline: 'healthcare expienditure',
        owner: 'venkat',
        type: 'Time-series-auto',
        description: '..'
      }
    ]
  }
];
