import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Avatar,
  Button,
  Grid,
  ListItem,
  ListItemText,
  Divider
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';
import InputIcon from '@mui/icons-material/Input';
import Logo from './Logo';
//import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
const DashboardNavbar = ({ onMobileNavOpen, props,signOut, ...rest }) => {
  const [notifications] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  let users = JSON.parse(localStorage.getItem('oauth'));
  return (
    <AppBar elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box sx={{ ml: '20px' }}>
      
          {/* <Button
            color="inherit"
            size="small"
            href="/ai/datasets"
          // onClick={(e)=>e.preventDefault()}
            style={{ textTransform: 'none' }}
          >
            Datasets
          </Button> */}
           
          <Link style={{color:'white'}} to="/ai/projects">Projects</Link>&nbsp;&nbsp;
           <Link style={{color:'white'}} to="/ai/datasets">Data</Link>&nbsp;&nbsp;
           <Link style={{color:'white'}} to="/ai/experiments">Experiments</Link>&nbsp;&nbsp;
           <Link style={{color:'white'}} to="/ai/predictions">Predictions</Link>
          {/* <Button
            color="inherit"
            size="small"
            href="/ai/experiments"
            //onClick={(e)=>e.preventDefault()}
            style={{ textTransform: 'none' }}
          >
            Experiments
          </Button>
          <Button
            color="inherit"
            size="small"
            href="/ai/projects"
            //onClick={(e)=>e.preventDefault()}
            style={{ textTransform: 'none' }}
          >
            Models
          </Button> */}
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Hidden only="sm">
            <ListItemText
              //  style={{ marginTop: '20px', marginLeft: '15px' }}
              primary={
                <span
                  style={{
                    fontSize: '14px',
                    fontWeight: '500px',
                    color: 'white'
                  }}
                >
                  {users === null ? '' : users.sbmUserId}
                </span>
              }
              secondary={
                <span
                  style={{ fontSize: '10px', float: 'right', color: 'white' }}
                >
                  {users === null ? '' : users.roles && users.roles[0]}
                </span>
              }
            />
          </Hidden>
          &nbsp;
          <Avatar
            alt="Remy Sharp"
            src="/static/images/pic.jpeg"
            style={{ width: '33px', height: '33px', marginTop: '10px' }}
            onClick={handleClick}
          />
        </Box>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          style={{ marginTop: '48px' }}
        >
          {' '}
          <div style={{ paddingRight: '7px', paddingLeft: '7px' }}>
            <Grid container direction="row" style={{ width: '400px' }}>
              <Grid item xs={12}>
                <div style={{ display: 'flex' }}>
                  <div>
                    <Avatar
                      src="/assets/images/pic.jpeg"
                      name="Admin"
                      size="88"
                      //  round={true}

                      style={{
                        border: '1px solid #0055B4',
                        marginLeft: '13px',
                        width: '80px',
                        height: '80px'
                        // width: '45%', height: '45%', marginLeft: '50px'
                      }}
                    />
                  </div>
                  <div>
                    <ListItemText
                      style={{ marginTop: '20px', marginLeft: '15px' }}
                      primary={
                        users === null ? (
                          ''
                        ) : (
                          <span
                            style={{
                              fontSize: '14px',
                              fontWeight: '500px',
                              color: 'black'
                            }}
                          >
                            {users.sbmUserId}
                          </span>
                        )
                      }
                      secondary={
                        <span style={{ fontSize: '12px', color: '#6979AE' }}>
                          {users === null ? '' : users.roles && users.roles[0]}
                        </span>
                      }
                    />
                  </div>
                </div>
                <Divider
                  style={{ backgroundColor: '#C8D2DC', marginTop: '7px' }}
                />
              </Grid>
              <Grid item xs={12}>
                <div>
                  <ListItem
                    onClick={() =>
                      window.open(
                        'http://myaccount.collabkare.com/profile',
                        '_blank'
                      )
                    }
                  >
                    <i
                      className="fa fa-user"
                      style={{
                        color: '#A8AFB9',
                        fontSize: '21px',
                        marginRight: '16px'
                      }}
                    ></i>
                    <ListItemText
                      primary="My Profile"
                      style={{ color: '#112558' }}
                    />
                  </ListItem>
                  <ListItem
                    style={{ marginTop: '-14px' }}
                    onClick={() =>
                      window.open(
                        'http://myaccount.collabkare.com/profile',
                        '_blank'
                      )
                    }
                  >
                    <i
                      className="fa fa-cog"
                      style={{
                        color: '#A8AFB9',
                        fontSize: '21px',
                        marginRight: '16px'
                      }}
                    ></i>
                    <ListItemText
                      primary="Manage My Account"
                      style={{ color: '#112558' }}
                    />
                  </ListItem>
                </div>
                <div>
                  <Divider
                    style={{ backgroundColor: '#C8D2DC', marginTop: '7px' }}
                  />
                  <ListItemText
                    primary="Roles"
                    style={{
                      color: '#112558',
                      marginLeft: '16px',
                      marginTop: '5px'
                    }}
                  />
                  {props.users == null
                    ? ''
                    : props.users.roles &&
                      props.users.roles.map((option, i) => (
                        <div
                          key={`Key${i}`}
                          onClick={() => {
                            localStorage.setItem('Role', option);
                          }}
                        >
                          <ListItem
                            button
                            // onClick={this.handleClose}
                          >
                            <i
                              className="fa fa-user"
                              style={{
                                color: '#A8AFB9',
                                fontSize: '21px',
                                marginRight: '16px'
                              }}
                            ></i>
                            <ListItemText
                              primary={
                                <span
                                  style={{ fontSize: '14px', color: 'black' }}
                                >
                                  {option.slice(5).replace(/_/g, ' ')}
                                </span>
                              }
                            />
                          </ListItem>
                        </div>
                      ))}
                </div>
              </Grid>
              <Grid item xs={12}>
                <Divider
                  style={{ backgroundColor: '#C8D2DC', marginTop: '7px' }}
                />
                <ListItem
                  button
                  onClick={()=>props.signOut()}
                >
                  <i
                    className="fa fa-sign-out"
                    style={{
                      color: '#A8AFB9',
                      fontSize: '21px',
                      marginRight: '16px'
                    }}
                  ></i>
                  <ListItemText
                    primary="Sign Out"
                    style={{ color: '#112558' }}
                  />
                </ListItem>
              </Grid>
            </Grid>
          </div>
          {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
          <MenuItem onClick={handleClose}>My account</MenuItem>
          <MenuItem onClick={handleClose}>Logout</MenuItem> */}
        </Menu>
        {/* <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden> */}
      </Toolbar>
    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func
};

export default DashboardNavbar;
// const mapStateToProps = (state) => {
//   return {
//     users: state.user.oauthUser
//   };
// };

// export default connect(mapStateToProps, null)(DashboardNavbar);
