import * as actionTypes from './types';
import { setOauthUserInfo, saveSandboxManagerUser } from './user';
import API from '../../api/api';
import Cookies from 'universal-cookie';
import FHIR from 'fhirclient';
let fhirClient = null;

// const getQueryParams = (url) => {
//   if (url.search) {
//     let urlParams;
//     let match,
//       pl = /\+/g, // Regex for replacing addition symbol with a space
//       search = /([^&=]+)=?([^&]*)/g,
//       decode = function (s) {
//         return decodeURIComponent(s.replace(pl, ' '));
//       },
//       query = url.search.substring(1);

//     urlParams = {};
//     while ((match = search.exec(query)))
//       urlParams[decode(match[1])] = decode(match[2]);
//     return urlParams;
//   } else {
//     window.location = '/';
//   }
// };

// export function fhirLogin() {
//   return {
//     type: actionTypes.FHIR_LOGIN
//   };
// }

// export function fhirLoginSuccess() {
//   return {
//     type: actionTypes.FHIR_LOGIN_SUCCESS
//   };
// }

export function goHome() {
  sessionStorage && sessionStorage.clear && sessionStorage.clear();
  localStorage && localStorage.clear && localStorage.clear();

  let cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    let spcook = cookies[i].split('=');
    deleteCookie(spcook[0]);
  }

  function deleteCookie(cookiename) {
    let d = new Date();
    d.setDate(d.getDate() - 1);
    let expires = ';expires=' + d;
    let name = cookiename;
    document.cookie = name + '=' + expires + '; path=/acc/html';
  }

  window.location = window.location.origin;
}

// export function fhirLoginFail() {
//   return {
//     type: actionTypes.FHIR_LOGIN_FAIL
//   };
// }

// export function clearToken() {
//   return {
//     type: actionTypes.FHIR_CLEAR_TOKEN
//   };
// }

// export function fhirInit() {
//   return {
//     type: actionTypes.FHIR_INIT
//   };
// }

export function setFhirClient(fhirClient) {
  return {
    type: actionTypes.FHIR_CLIENT,
    fhirClient: fhirClient
  };
}

export function hspcAuthorized() {
  return {
    type: actionTypes.FHIR_HSPC_AUTHORIZED
  };
}

// export function setFhirVersion(fhirVersion) {
//   return {
//     type: actionTypes.FHIR_VERSION,
//     fhirVersion: fhirVersion
//   };
// }

// export function saveSandboxApiEndpointIndex(index) {
//   return {
//     type: actionTypes.SAVE_ENDPOINT_INDEX,
//     index: index
//   };
// }
export function resetGlobalError() {
  return {
    type: actionTypes.SET_ERROR_TO_SHOW,
    payload: { error: undefined }
  };
}

export function setGlobalError(error) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_ERROR_TO_SHOW,
      payload: { error }
    });
  };
}
export function signOut() {
  return (dispatch, getState) => {
    let state = getState();
    sessionStorage.clear();
    localStorage.clear();
    const cookies = new Cookies();
    cookies.remove('iPlanetDirectoryPro');
    cookies.remove('id');
    let domain = cookies.get('domain');
    let managerLogoutdomainUrl = 'https://auth.collabkare.com/am/oauth2/';

    let domainurl = `${managerLogoutdomainUrl}${domain}/connect/endSession`;
    let managerLogoutUrl =
      'https://auth.collabkare.com/am/oauth2/connect/endSession';

    window.location.href = `${
      domain === undefined ? managerLogoutUrl : domainurl
    }?id_token_hint=${
      localStorage.token
    }&post_logout_redirect_uri=${'https://myaccount.collabkare.com'}/login`;
  };
}

// // function queryFhirVersion(dispatch, fhirClient, state) {
// //   fhirClient.api.conformance({}).then((response) => {
// //     dispatch(setFhirVersion(response.data.fhirVersion));
// //     state.sandbox.sandboxApiEndpointIndexes.forEach((sandboxEndpoint) => {
// //       if (response.data.fhirVersion === sandboxEndpoint.fhirVersion) {
// //         dispatch(saveSandboxApiEndpointIndex(sandboxEndpoint.index));
// //       }
// //     });
// //   });
// // }

// export function authorize(url, state, sandboxId) {
//   const cookies = new Cookies();

//   let cc =
//     cookies.get('domain') == undefined ? 'claimryte' : cookies.get('domain');
//   let serviceUrl = `https://fhir-api-r4.collabkare.com/${cc}/data`;
//   window.FHIR = FHIR;
//   window.FHIR.oauth2
//     .authorize({
//       client_id: 'collabkare-ai',
//       redirect_uri: window.location.origin + '/after-auth',
//       scope:
//         'openid user/*.* user.cheif ai.invite ai.write ai.delete profile dynamic_client_registration profile email address phone',
//       iss: serviceUrl
//     })
//     .then((err) => {
//       console.log(err);
//      // goHome();
//     });

// }

// export function init() {
//   return (dispatch, getState) => {
//     const state = getState();
//     dispatch(clearToken());
//     authorize(window.location, state);
//     console.log(state);
//   };
// }

// export function afterFhirAuth(url) {
//   return (dispatch) => {
//     // let params = getQueryParams(url);
//     // if (params && params.code) {
//       dispatch(clearToken());

//       try {
//         // For some reason if we make the call too quickly the server responds that the
//         // token is invalid... we need to slow down the call a bit to prevent random
//         // 400 errors
//         setTimeout(() => {
//             FHIR.oauth2
//                   .ready()
//                   .then((client) => {
//                     dispatch(fhirauth_setSmart(client))
//                     console.log(client, window);
//                   })

//                   .catch((err) => console.log(err));

//         }, 1000);
//       } catch (e) {
//         goHome();
//       }
//     //}
//   };
// }

export function fhirauth_setSmart(smart, redirect = null) {
  return (dispatch, getState) => {
    let state = getState();
    //console.log(state)
    //let configuration = state.config.xsettings.data.sandboxManager;
    dispatch(hspcAuthorized());
    dispatch(setFhirClient(smart));
    fhirClient = smart;
    window.fhirClient = smart;
    // queryFhirVersion(dispatch, fhirClient, state);
    API.get('https://manager-api.collabkare.com/userinfo', dispatch)
      .then((data) => {
        dispatch(
          setOauthUserInfo(
            data.sub,
            data.preferred_username,
            data.name,
            data.roles
          )
        );
        API.get(
          'https://manager-api.collabkare.com' +
            '/user?sbmUserId=' +
            encodeURIComponent(data.sub),
          dispatch
        )
          .then((data2) => {
            dispatch(saveSandboxManagerUser(data2));
            let state = getState();

            //localStorage.setItem("token", JSON.stringify(fhirClient));

            localStorage.setItem(
              'token',
              JSON.stringify(fhirClient.state.tokenResponse.id_token).slice(
                1,
                -1
              )
            );
            // console.log(data2)
          })
          .catch(() => {
            console.log('errr');
          });
      })
      .catch(() => {
        goHome();
      });
  };
}
