import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import CssBaseline from '@mui/material/CssBaseline'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import Container from '@mui/material/Container'
import Fab from '@mui/material/Fab'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Zoom from '@mui/material/Zoom'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import TwitterIcon from '@mui/icons-material/Twitter'
import FacebookIcon from '@mui/icons-material/Facebook'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import { Divider } from '@mui/material'
import Chip from '@mui/material/Chip'
import MobileStepper from '@mui/material/MobileStepper'
import Paper from '@mui/material/Paper'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'
import { connect } from 'react-redux'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import WhatshotIcon from '@mui/icons-material/Whatshot'
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic'
// import EcoIcon from "@mui/icons-material/Eco";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import SecurityIcon from '@mui/icons-material/Security'
import Asset5 from '../assets/icons/Asset-5.svg'
import Img1 from '../assets/icons/app2.png'
import Cookies from 'universal-cookie'
import FHIRBLOG from '../assets/icons/FHIR-Blog.png'
import ADMINAJX from '../assets/icons/admin-ajax.png'
import DOWNLOAD from '../assets/icons/download.jpg'
import SGS from '../assets/icons/Hospital-performance.png'
const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const classes = {
    roots: {
        position: 'fixed',
        bottom: '16px', // Adjusted to static values
        right: '16px', // Adjusted to static values
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    main: {
        marginTop: '64px', // Adjusted to static values
        marginBottom: '16px', // Adjusted to static values
    },
    footer: {
        padding: '24px 16px', // Adjusted to static values
        marginTop: 'auto',
        color: 'white',
    },
    mainFeaturedPost: {
        position: 'relative',
        backgroundColor: '#424242', // Replace with a static color
        color: 'white',
        backgroundImage:
            '-webkit-linear-gradient(0deg, #3c96ff 0%, #2dfbff 100%)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
    },
    mainFeaturedPostContent: {
        position: 'relative',
        padding: '16px', // Adjusted to static values
    },
    ui: {
        margin: 0,
        padding: 0,
        listStyle: 'none',
    },
    appbar: {
        backgroundImage:
            '-webkit-linear-gradient(0deg, #3c96ff 0%, #2dfbff 100%)',
    },
    cardGrid: {
        paddingTop: '64px', // Adjusted to static values
        paddingBottom: '64px', // Adjusted to static values
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        width: '200px',
        height: '200px',
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundSize: '185px',
    },
    cardContent: {
        flexGrow: 1,
        textAlign: 'center',
    },
    link: {
        margin: '8px 12px', // Adjusted to static values
    },
    ll: {
        flexGrow: 1,
    },
    roo: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#f6fbfd',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        padding: '24px', // Adjusted to static values
        maxWidth: 900,
    },
    ava: {
        width: '100%',
        maxWidth: 500,
    },
    ima: {
        marginLeft: '15px',
        backgroundImage:
            '-webkit-linear-gradient(0deg, #3c96ff 0%, #2dfbff 100%)',
    },
    imaa: {
        marginRight: '15px',
        backgroundImage:
            '-webkit-linear-gradient(0deg, #3c96ff 0%, #2dfbff 100%)',
    },
}

const Start = (props) => {
    const cookies = new Cookies()
    const [activeStep, setActiveStep] = React.useState(0)
    const maxSteps = tutorialSteps.length

    const handleStepChange = (step) => {
        setActiveStep(step)
    }

    useEffect(() => {
        cookies.remove('organization')
    }, [])
    return (
        <React.Fragment>
            <CssBaseline />
            <AppBar elevation={0}>
                <Toolbar style={classes.appbar}>
                    <img src={Asset5} style={{ width: '200px' }} />
                    <div style={classes.ll}></div>

                    {/* // : ""} */}

                    <Button color="inherit" href="/start">
                        Login
                    </Button>
                </Toolbar>
            </AppBar>
            <Toolbar id="back-to-top-anchor" />

            <div style={classes.mainFeaturedPost}>
                {<img style={{ display: 'none' }} />}
                <div style={classes.overlay} />
                <Grid container>
                    <Grid item md={6}>
                        <div style={classes.mainFeaturedPostContent}>
                            <h2
                                style={{ lineHeight: '42px', fontSize: '30px', fontWeight:500 }}
                            >
                                {
                                    'Simplifying the complicated healthcare system with next-generation Smart technology and Collaborative solutions.'
                                }
                            </h2>
                        </div>
                    </Grid>
                    <Grid item md={6}>
                        <div
                            style={{
                                fontSize: '45px',
                                color: '#fff',
                                fontWeight: 500,
                                margin: '35px',
                                textAlign: 'right',
                            }}
                        >
                            Kare AI
                        </div>
                    </Grid>
                </Grid>
            </div>

            <div style={{ backgroundColor: '#f6fbfd' }}>
                <Container maxWidth="lg">
                    <br />
                    <div
                        style={{
                            textAlign: 'center',
                            color: '#191c1e',
                            fontSize: '22px',
                            fontWeight: '700',
                            marginTop: '20px',
                        }}
                    >
                        <Chip label="EXPERTISE" />
                    </div>

                    <h2
                        style={{
                            textAlign: 'center',
                            color: '#3c96ff',
                            fontSize: '22px',
                        }}
                    >
                        Features
                    </h2>
                    <Grid
                        container
                        spacing={4}
                        direction="row"
                        alignItems="center"
                        justify="center"
                    >
                        <Grid item xs={12} sm={4}>
                            <List style={classes.ava}>
                                <ListItem>
                                    <ListItemText
                                        primary={
                                            <span
                                                style={{
                                                    float: 'right',
                                                    textAlign: 'right',
                                                }}
                                            >
                                                Health Information Exchange
                                            </span>
                                        }
                                        secondary={
                                            <span
                                                style={{
                                                    float: 'right',
                                                    textAlign: 'right',
                                                }}
                                            >
                                                Performance and track aspects
                                                like patient progress and
                                                outcomes.
                                            </span>
                                        }
                                    />
                                    <ListItemAvatar>
                                        <Avatar style={classes.ima}>
                                            {/* <EcoIcon /> */}
                                        </Avatar>
                                    </ListItemAvatar>
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary={
                                            <span
                                                style={{
                                                    float: 'right',
                                                    textAlign: 'right',
                                                }}
                                            >
                                                FHIR Protocol
                                            </span>
                                        }
                                        secondary={
                                            <span
                                                style={{
                                                    float: 'right',
                                                    textAlign: 'right',
                                                }}
                                            >
                                                FHIR is implemented on top of
                                                HL7 and the HTTPS protocol.
                                            </span>
                                        }
                                    />
                                    <ListItemAvatar>
                                        <Avatar style={classes.ima}>
                                            <WhatshotIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary={
                                            <span
                                                style={{
                                                    float: 'right',
                                                    textAlign: 'right',
                                                }}
                                            >
                                                Health Care Data Standards
                                            </span>
                                        }
                                        secondary={
                                            <span
                                                style={{
                                                    float: 'right',
                                                    textAlign: 'right',
                                                }}
                                            >
                                                Retrieval of information
                                                associated with health care
                                                applications.
                                            </span>
                                        }
                                    />
                                    <ListItemAvatar>
                                        <Avatar style={classes.ima}>
                                            <HeadsetMicIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <img style={{ width: '100%' }} src={Img1} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <List style={classes.ava}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar style={classes.imaa}>
                                            <SecurityIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={<span>Security</span>}
                                        secondary={
                                            <span>
                                                To ensuring the security and
                                                safety of the hospital patients.
                                            </span>
                                        }
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar style={classes.imaa}>
                                            <StarBorderIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={<span>IHE Protocol</span>}
                                        secondary={
                                            <span>
                                                Promotes the standards such as
                                                DICOM and HL7 to address.
                                            </span>
                                        }
                                    />
                                </ListItem>

                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar style={classes.imaa}>
                                            <HelpOutlineIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <span>
                                                Clinical Decision Support
                                            </span>
                                        }
                                        secondary={
                                            <span>
                                                Provides, staff, patients or
                                                other individuals with knowledge
                                                and person-specific information.
                                            </span>
                                        }
                                    />
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                    <br />
                </Container>
            </div>
            <div style={{ backgroundColor: '#fff' }}>
                <Container maxWidth="lg">
                    <br />
                    <div
                        style={{
                            textAlign: 'center',
                            color: '#191c1e',
                            fontSize: '22px',
                            fontWeight: '700',
                            marginTop: '20px',
                        }}
                    >
                        <Chip label="SOLUTIONS" />
                    </div>

                    <h2
                        style={{
                            textAlign: 'center',
                            color: '#3c96ff',
                            fontSize: '22px',
                        }}
                    >
                        By Technology
                    </h2>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="center"
                        justify="center"
                        // style={{ minHeight: '100vh' }}
                    >
                        <Grid item xs={12} sm={6}>
                            <img
                                src={FHIRBLOG}
                                style={{ maxWidth: '100%', height: 'auto' }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <h3>FHIR IG Registry</h3>
                            <p>
                                FHIR is a platform specification. It describes a
                                general set of capabilities that can be used to
                                solve many healthcare data exchange problems.
                                Because FHIR is used for all sorts of problems
                                in many jurisdictions using a variety of
                                architectures and information exchange patterns,
                                the FHIR specification is very general and there
                                are many ways to solve a problem. For this
                                reason, implementers must make choices and for
                                particular solutions to be interoperable, the
                                implementations must make the same set of
                                choices.
                            </p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <h3>Sandboxes</h3>
                            <p>
                                You can create Sandboxes by project,
                                application, multiple APIs, single services or
                                anything else you like. Every Sandbox has a
                                unique URL you’ll use when sending requests to
                                your mock services. Within a Sandbox you create
                                routes that specify how your Sandbox responds to
                                client requests to a particular endpoint.
                                Sandbox provides a DSL for quickly modelling
                                mock services in Javascript with minimal effort.
                                You can inspect the Request and code behaviour
                                to set the Response. For advanced scenarios you
                                can use State to store data between requests.
                            </p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <img
                                src={ADMINAJX}
                                style={{ maxWidth: '100%', height: 'auto' }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <img
                                src={DOWNLOAD}
                                style={{ maxWidth: '100%', height: 'auto' }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <h3>Health Exchange</h3>
                            <p>
                                Health Information Exchange (HIE) is the
                                mobilization of health care information
                                electronically across organizations within a
                                region, community or hospital system. HIE
                                provides the capability to electronically move
                                clinical information among different health care
                                information systems.
                            </p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <h3>Analytics</h3>
                            <p>
                                Organizing resources, allocating budgets,
                                identifying potential bottlenecks and responding
                                fast to emerging crises are all par for the
                                course – and that’s precisely where business
                                intelligence can help. Hospitals increasingly
                                use data analysis to keep an eye on overall
                                hospital performance and track aspects like
                                patient progress and outcomes, track bed
                                occupancy, improve bedside care, and correct
                                inefficiencies in the way they deal with
                                patients or administer treatment and services.
                            </p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <img
                                src={SGS}
                                style={{ maxWidth: '100%', height: 'auto' }}
                            />
                        </Grid>
                    </Grid>
                </Container>
                <br />
                <div style={{ background: '#f6fbfd', paddingBottom: '30px' }}>
                    <Container maxWidth="md">
                        <br />
                        <div
                            style={{
                                textAlign: 'center',
                                color: '#191c1e',
                                fontSize: '22px',
                                fontWeight: '700',
                                marginTop: '20px',
                            }}
                        >
                            <Chip label="Testimonial" />
                        </div>
                        <h2
                            style={{
                                textAlign: 'center',
                                color: '#3c96ff',
                                fontSize: '22px',
                            }}
                        >
                            Bring Quality Care to Patients
                        </h2>
                        <Paper square elevation={0} style={classes.header}>
                            <AutoPlaySwipeableViews
                                axis={'x'}
                                index={activeStep}
                                onChangeIndex={handleStepChange}
                                enableMouseEvents
                            >
                                {tutorialSteps.map((step, index) => (
                                    <div key={step.label}>
                                        {Math.abs(activeStep - index) <= 2 ? (
                                            <div>
                                                {step.id}
                                                <h3
                                                    style={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    {step.label}
                                                </h3>
                                            </div>
                                        ) : null}
                                    </div>
                                ))}
                            </AutoPlaySwipeableViews>
                        </Paper>
                        <MobileStepper
                            style={classes.roo}
                            steps={maxSteps}
                            position="static"
                            variant="dots"
                            activeStep={activeStep}
                        />
                    </Container>
                </div>
            </div>
            {/* </Box>
            </Container> */}
            <ScrollTop {...props}>
                <Fab
                    color="secondary"
                    size="small"
                    aria-label="scroll back to top"
                >
                    <KeyboardArrowUpIcon />
                </Fab>
            </ScrollTop>
            <footer style={classes.appbar}>
                {/* Footer */}
                <Container
                    maxWidth="lg"
                    component="footer"
                    style={classes.footer}
                >
                    <Grid container spacing={4} justify="space-evenly">
                        {footers.map((footer) => (
                            <Grid item xs={6} sm={3} key={footer.title}>
                                <Typography variant="h6" gutterBottom>
                                    {footer.title}
                                </Typography>
                                <ul style={classes.ui}>
                                    {footer.description.map((item) => (
                                        <li key={item}>
                                            <Link
                                                href="#"
                                                variant="subtitle1"
                                                color="textSecondary"
                                                style={{
                                                    color: 'white',
                                                    textDecoration: 'none',
                                                }}
                                            >
                                                {item}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
                {/* End footer */}
                <Divider style={{ backgroundColor: 'whitesmoke' }} />
                <br />
                <Container maxWidth="lg">
                    {/* <Typography variant="body1">My sticky footer can be found here.</Typography> */}

                    <Copyright />
                </Container>
                <br />
            </footer>
        </React.Fragment>
    )
}
const mapStateToProps = (state) => {
    return {
        MANAGER_URL: 'https://cloud.collabkare.app/',
        PORTAL_URL: 'https://myaccount.collabkare.app/',
        MARKET_PLACE: 'https://cloud.collabkare.app/',
    }
}
const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Start)

function ScrollTop(props) {
    const { children, window } = props
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 100,
    })

    const handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector(
            '#back-to-top-anchor'
        )

        if (anchor) {
            anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
    }

    return (
        <Zoom in={trigger}>
            <div
                onClick={handleClick}
                role="presentation"
                style={classes.roots}
            >
                {children}
            </div>
        </Zoom>
    )
}

ScrollTop.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
}
function Copyright() {
    return (
        <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
        >
            <Grid item xs={12} sm={6}>
                <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ color: 'white' }}
                >
                    {'Copyright © '}
                    <Link
                        color="inherit"
                        href="https://staging.collabkare.com/"
                    >
                        staging.collabkare.com
                    </Link>{' '}
                    {new Date().getFullYear()}
                    {'.'}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <div style={{ float: 'right' }}>
                    <Link
                        color="inherit"
                        href="https://www.facebook.com/CollabKare/"
                    >
                        <FacebookIcon style={{ color: 'white' }} />
                    </Link>
                    <Link
                        color="inherit"
                        href="https://twitter.com/collabkare/"
                    >
                        <TwitterIcon style={{ color: 'white' }} />
                    </Link>
                    <Link
                        color="inherit"
                        href="https://www.linkedin.com/in/collabkare-inc-3968161ab/"
                    >
                        <LinkedInIcon style={{ color: 'white' }} />
                    </Link>
                </div>
            </Grid>
        </Grid>
    )
}

const footers = [
    {
        title: 'Products',
        description: [
            'cKare MD App',
            'cKare vHospital',
            'ckare Ai',
            'cKare Exchange',
            'cKare Analytic',
        ],
    },
    {
        title: 'Solutions',
        description: [
            'FHIR IG Registry',
            'App Gallery',
            'Sand Boxes',
            'Health Exchange',
            'Analytics',
        ],
    },
    {
        title: 'Resources',
        description: ['Events', 'Blog', 'Knowledge Hub', 'News'],
    },
    {
        title: 'Contact',
        description: [
            'Sagacity Heights H#1-53 plot no. 27, Phase -1, 4 th floor, Kavuri Hills Phase 1 ,Kavuri Hills, Madhapur, Hyderabad, Telangana 500081, India',
            'Phone: +91 (40) 6745 3961',
            'Email: admin@collabkare.com',
        ],
    },
]

const tutorialSteps = [
    {
        label: 'Solutions',
        id: 'Collabkare Solutions is helping hospitals, health plans and small group practices with practice improvement, peer review and disease specific documentation improvement improvement services through its analytics and dashboards.',
    },
    {
        label: 'Expertise',
        id: 'Collabkare Solutions is helping hospitals, health plans and small group practices with practice improvement, peer review and disease specific documentation improvement improvement services through its analytics and dashboards.',
    },
    {
        label: 'Use Cases',
        id: 'Collabkare Solutions is helping hospitals, health plans and small group practices with practice improvement, peer review and disease specific documentation improvement improvement services through its analytics and dashboards.',
    },
]
