import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/components/DashboardLayout';
import MainLayout from 'src/components/MainLayout';
import Account from 'src/pages/Account';
import CustomerList from 'src/pages/CustomerList';
import Dashboard from 'src/pages/Dashboard';
import Login from 'src/pages/Login';
import NotFound from 'src/pages/NotFound';
import ProductList from 'src/pages/ProductList';
import Register from 'src/pages/Register';
import Settings from 'src/pages/Settings';
import H2o from 'src/components/h2odriverless/h20';
import Predict from 'src/components/h2odriverless/predict';
import Experiment from 'src/components/h2odriverless/experiment';
import ExperimentPage from 'src/components/h2odriverless/experimentspage';
import ExprementLaunch from 'src/components/h2odriverless/exprementslaunch';
import Projects from 'src/components/h2odriverless/projects';
import Start from 'src/pages/start';
import AfterAuth from 'src/pages/afterauth'
import AiProjects from 'src/components/h2odriverless/aiprojects';
import ViewProject from 'src/components/h2odriverless/viewproject'
import Landing from 'src/pages/LandingPage'
const routes = [
  {
    path: 'ai',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <Account /> },
      { path: 'customers', element: <CustomerList /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'products', element: <ProductList /> },
      { path: 'datasets', element: <H2o /> },
      { path: 'predict', element: <Predict /> },
      { path: 'experiment', element: <Experiment /> },
      { path: 'experiments', element: <ExperimentPage /> },
       { path: 'experimentlaunch', element: <ExprementLaunch /> },
       { path: 'predictions', element: <Projects /> },
       { path: 'projects', element: <AiProjects /> },
       { path: ':id', element: <ViewProject /> },
      { path: 'settings', element: <Settings /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <Landing />,
   
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'start', element: <Start /> },
      { path: 'after-auth', element: <AfterAuth /> },
      
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      { path: '404', element: <NotFound /> },

      { path: '/', element: <Navigate to="start" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
