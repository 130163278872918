import React from 'react';
import {
  IconButton,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Icon,
  TablePagination,
  TableContainer,
  Paper,
  Box
} from '@mui/material';
import Breadcrumb from './Breadcrumb';
import { useNavigate } from 'react-router-dom';
const List = [
  {
    name: 'cc default',
    description: 'Description',
    Tarining_Dataswts: 1,
    Validation_Datasets: '0',
    Test_Datasets: '1',
    Experiments: '3'
  },
  {
    name: 'cc default',
    description: 'Description',
    Tarining_Dataswts: 5,
    Validation_Datasets: '0',
    Test_Datasets: '0',
    Experiments: '4'
  }
];

const Projects = () => {
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const navigate = useNavigate();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
  };

  return (
    <Box
      sx={{
        p: 4
      }}
    >
      <Breadcrumb
        routeSegments={[
          { name: 'Predictions', path: 'ai/predictions' }
          //{ name: 'Autocomplete' }
        ]}
      />
      {/* {console.log(window.location.pathname)} */}
      <Box
        sx={{
          mt: 1,
          p: 1,
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button
          variant="contained"
          aria-controls="simple-menu"
          aria-haspopup="true"
        >
          + NEW PROJECT
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Training Datasets</TableCell>
              <TableCell>Validation Datasets</TableCell>
              <TableCell>Test Datasets</TableCell>
              <TableCell>Experiments</TableCell>
              <TableCell>status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {List.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            ).map((res, index) => (
              <TableRow key={index}>
                <TableCell align="left">{res.name}</TableCell>
                <TableCell align="left">{res.description}</TableCell>
                <TableCell align="left">{res.Tarining_Dataswts}</TableCell>
                <TableCell>{res.Validation_Datasets}</TableCell>
                <TableCell>{res.Test_Datasets}</TableCell>
                <TableCell>{res.Experiments}</TableCell>
                <TableCell>
                  <Icon onClick={() => navigate('/ai/experimentlaunch', { replace: true })}>launch</Icon>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={List.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page'
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page'
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          // onChangePage={handleChangePage}
          // onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Box>
  );
};

export default Projects;
