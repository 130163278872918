import React, { Component } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import FHIR from 'fhirclient';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Cookies from 'universal-cookie';
import  './start.scss'
class Start extends Component {
  componentDidMount() {
    const cookies = new Cookies();
    let domain =
      cookies.get('domain') == undefined ? 'claimryte' : cookies.get('domain');

    FHIR.oauth2
      .authorize({
        client_id: 'collabkare-ai',
        redirect_uri: window.location.origin + '/after-auth',
        scope:
          'openid user/*.* user.cheif ai.invite ai.write ai.delete profile dynamic_client_registration profile email address phone',
        //iss: 'https://fhir-api-r4.collabkare.com/claimryte/data'
        iss: `https://fhir.collabkare.com/${domain}/data`
      })
      .catch((err) => {
        console.log(err);
        window.location.href = '/';
      });
  }

  render() {
    return (
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="md">
          <div style={{ marginTop: '70px' }}>
            <img src="/static/images/Asset-9.svg" />
            <br />
            <div style={{ textAlign: 'center' }}>
              <CircularProgress />
            </div>

            <div style={{ textAlign: 'center' }}>
              <span>
                Welcome to the CollabKare Virtual Hospital,{' '}
                <a href="">click here</a> if you're not redirected to the login
                screen.
              </span>
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}
export default Start;
