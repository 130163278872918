import 'react-perfect-scrollbar/dist/css/styles.css';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import routes from 'src/routes';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
//import { store, persistor } from "./Redux/store"
//import jwt from 'jsonwebtoken';
import configureStore from './Redux/store';
const { persistor, store } = configureStore();
import jwt_decode from 'jwt-decode';
//import history from './history';
//import { BrowserRouter as Router, Route } from 'react-router-dom';
//import wrapHOC from './auth'
import PerfectScrollbar from 'react-perfect-scrollbar';
import './App.scss'
const App = () => {
  const routing = useRoutes(routes);
  // if (localStorage.getItem('token')) {
  //   const jwt_Token_decoded = jwt_decode(localStorage.getItem('token'));
  //   const { exp } = jwt_Token_decoded;
  //   console.log(exp);
  //   const expirationTime = exp * 1000 - 60000;
  //   if (Date.now() >= expirationTime) {
  //     console.log('token expired');
  //     alert('token expired')
  //   } else {
  //     console.log('token not expired');
  //   }
  // }
  


  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {/* <PerfectScrollbar  style={{height:'500px'}}> */}
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          {routing}
        </ThemeProvider>
        {/* </PerfectScrollbar> */}
      </PersistGate>
    </Provider>
  );
};

export default App;
