import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Divider, Grid } from '@mui/material';
export default function ExpertSetting() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      {/* <Button onClick={handleClickOpen}>EXPERT SETTINGS</Button>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Expert Experiment Setttings
        </DialogTitle> */}
      <DialogContent>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab
            label="EXPERIMENT"
            {...a11yProps(0)}
            style={{ minWidth: '10%' }}
          />
          <Tab label="MODEL" {...a11yProps(1)} style={{ minWidth: '10%' }} />
          <Tab label="FEATURES" {...a11yProps(2)} style={{ minWidth: '10%' }} />
          <Tab
            label="TIMESERIES"
            {...a11yProps(3)}
            style={{ minWidth: '10%' }}
          />
          <Tab label="NLP" {...a11yProps(4)} style={{ minWidth: '10%' }} />

          <Tab label="RECIPES" {...a11yProps(5)} style={{ minWidth: '10%' }} />
          <Tab label="SYSTEM" {...a11yProps(6)} style={{ minWidth: '10%' }} />
        </Tabs>
        <Divider />
        <br />
        <TabPanel value={value} index={0}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Feature engineering effort (0.10)"
                defaultValue="5"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Data distribution shift detection"
                defaultValue="5"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Data distribution shift detection of features"
                defaultValue="5"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Max allowed features shift (AUC) before dropping feature"
                defaultValue="0.999"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Leakage detection"
                defaultValue="auto"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Leakage detection dropping AUC/R2 threshold"
                defaultValue="0.999"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Max rows x columns for leakage"
                defaultValue="1000000"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Report permutation importance on original features"
                defaultValue="disabled"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Maximum number of rows to perform permutation based feature selection"
                defaultValue="1000000"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Maximum number of original features used"
                defaultValue="1000000"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Max number of original non-numeric features"
                defaultValue="0.95"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Max allowed fraction of uniques for int/float time series"
                defaultValue="30"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Max number of engineered features (-1=auto)"
                defaultValue="-1"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Correlation beyond which triggers to trigger feature selection model type"
                defaultValue="200"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="max allowed fraction of uniques for integer and categoricals cols"
                defaultValue="0.95"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="max number of unique values for int/float to be categoricals"
                defaultValue="50"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="max number of engineered features (-1=auto)"
                defaultValue="-1"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="correlation beyond which triggers mananicity constrains (if enabled)"
                defaultValue="0.1"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="max feature intraction depth"
                defaultValue="-1"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="enable target encoding (auto disables for time series)"
                defaultValue="auto"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Feature engineering effort (0.10)"
                defaultValue="5"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Data distribution shift detection"
                defaultValue="5"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Data distribution shift detection of features"
                defaultValue="5"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Max allowed features shift (AUC) before dropping feature"
                defaultValue="0.999"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Leakage detection"
                defaultValue="auto"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Leakage detection dropping AUC/R2 threshold"
                defaultValue="0.999"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Max rows x columns for leakage"
                defaultValue="1000000"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Report permutation importance on original features"
                defaultValue="disabled"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Maximum number of rows to perform permutation based feature selection"
                defaultValue="1000000"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Maximum number of original features used"
                defaultValue="1000000"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Max number of original non-numeric features"
                defaultValue="0.95"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Max allowed fraction of uniques for int/float time series"
                defaultValue="30"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Max number of engineered features (-1=auto)"
                defaultValue="-1"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Correlation beyond which triggers to trigger feature selection model type"
                defaultValue="200"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="max allowed fraction of uniques for integer and categoricals cols"
                defaultValue="0.95"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="max number of unique values for int/float to be categoricals"
                defaultValue="50"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="max number of engineered features (-1=auto)"
                defaultValue="-1"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="correlation beyond which triggers mananicity constrains (if enabled)"
                defaultValue="0.1"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="max feature intraction depth"
                defaultValue="-1"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="enable target encoding (auto disables for time series)"
                defaultValue="auto"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Feature engineering effort (0.10)"
                defaultValue="5"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Data distribution shift detection"
                defaultValue="5"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Data distribution shift detection of features"
                defaultValue="5"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Max allowed features shift (AUC) before dropping feature"
                defaultValue="0.999"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Leakage detection"
                defaultValue="auto"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Leakage detection dropping AUC/R2 threshold"
                defaultValue="0.999"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Max rows x columns for leakage"
                defaultValue="1000000"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Report permutation importance on original features"
                defaultValue="disabled"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Maximum number of rows to perform permutation based feature selection"
                defaultValue="1000000"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Maximum number of original features used"
                defaultValue="1000000"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Max number of original non-numeric features"
                defaultValue="0.95"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Max allowed fraction of uniques for int/float time series"
                defaultValue="30"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Max number of engineered features (-1=auto)"
                defaultValue="-1"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Correlation beyond which triggers to trigger feature selection model type"
                defaultValue="200"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="max allowed fraction of uniques for integer and categoricals cols"
                defaultValue="0.95"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="max number of unique values for int/float to be categoricals"
                defaultValue="50"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="max number of engineered features (-1=auto)"
                defaultValue="-1"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="correlation beyond which triggers mananicity constrains (if enabled)"
                defaultValue="0.1"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="max feature intraction depth"
                defaultValue="-1"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="enable target encoding (auto disables for time series)"
                defaultValue="auto"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Feature engineering effort (0.10)"
                defaultValue="5"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Data distribution shift detection"
                defaultValue="5"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Data distribution shift detection of features"
                defaultValue="5"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Max allowed features shift (AUC) before dropping feature"
                defaultValue="0.999"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Leakage detection"
                defaultValue="auto"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Leakage detection dropping AUC/R2 threshold"
                defaultValue="0.999"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Max rows x columns for leakage"
                defaultValue="1000000"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Report permutation importance on original features"
                defaultValue="disabled"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Maximum number of rows to perform permutation based feature selection"
                defaultValue="1000000"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Maximum number of original features used"
                defaultValue="1000000"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Max number of original non-numeric features"
                defaultValue="0.95"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Max allowed fraction of uniques for int/float time series"
                defaultValue="30"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Max number of engineered features (-1=auto)"
                defaultValue="-1"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Correlation beyond which triggers to trigger feature selection model type"
                defaultValue="200"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="max allowed fraction of uniques for integer and categoricals cols"
                defaultValue="0.95"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="max number of unique values for int/float to be categoricals"
                defaultValue="50"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="max number of engineered features (-1=auto)"
                defaultValue="-1"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="correlation beyond which triggers mananicity constrains (if enabled)"
                defaultValue="0.1"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="max feature intraction depth"
                defaultValue="-1"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="enable target encoding (auto disables for time series)"
                defaultValue="auto"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Feature engineering effort (0.10)"
                defaultValue="5"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Data distribution shift detection"
                defaultValue="5"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Data distribution shift detection of features"
                defaultValue="5"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Max allowed features shift (AUC) before dropping feature"
                defaultValue="0.999"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Leakage detection"
                defaultValue="auto"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Leakage detection dropping AUC/R2 threshold"
                defaultValue="0.999"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Max rows x columns for leakage"
                defaultValue="1000000"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Report permutation importance on original features"
                defaultValue="disabled"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Maximum number of rows to perform permutation based feature selection"
                defaultValue="1000000"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Maximum number of original features used"
                defaultValue="1000000"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Max number of original non-numeric features"
                defaultValue="0.95"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Max allowed fraction of uniques for int/float time series"
                defaultValue="30"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Max number of engineered features (-1=auto)"
                defaultValue="-1"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Correlation beyond which triggers to trigger feature selection model type"
                defaultValue="200"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="max allowed fraction of uniques for integer and categoricals cols"
                defaultValue="0.95"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="max number of unique values for int/float to be categoricals"
                defaultValue="50"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="max number of engineered features (-1=auto)"
                defaultValue="-1"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="correlation beyond which triggers mananicity constrains (if enabled)"
                defaultValue="0.1"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="max feature intraction depth"
                defaultValue="-1"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="enable target encoding (auto disables for time series)"
                defaultValue="auto"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={5}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Feature engineering effort (0.10)"
                defaultValue="5"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Data distribution shift detection"
                defaultValue="5"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Data distribution shift detection of features"
                defaultValue="5"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Max allowed features shift (AUC) before dropping feature"
                defaultValue="0.999"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Leakage detection"
                defaultValue="auto"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Leakage detection dropping AUC/R2 threshold"
                defaultValue="0.999"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Max rows x columns for leakage"
                defaultValue="1000000"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Report permutation importance on original features"
                defaultValue="disabled"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Maximum number of rows to perform permutation based feature selection"
                defaultValue="1000000"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Maximum number of original features used"
                defaultValue="1000000"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Max number of original non-numeric features"
                defaultValue="0.95"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Max allowed fraction of uniques for int/float time series"
                defaultValue="30"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Max number of engineered features (-1=auto)"
                defaultValue="-1"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Correlation beyond which triggers to trigger feature selection model type"
                defaultValue="200"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="max allowed fraction of uniques for integer and categoricals cols"
                defaultValue="0.95"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="max number of unique values for int/float to be categoricals"
                defaultValue="50"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="max number of engineered features (-1=auto)"
                defaultValue="-1"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="correlation beyond which triggers mananicity constrains (if enabled)"
                defaultValue="0.1"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="max feature intraction depth"
                defaultValue="-1"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="enable target encoding (auto disables for time series)"
                defaultValue="auto"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={6}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Feature engineering effort (0.10)"
                defaultValue="5"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Data distribution shift detection"
                defaultValue="5"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Data distribution shift detection of features"
                defaultValue="5"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Max allowed features shift (AUC) before dropping feature"
                defaultValue="0.999"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Leakage detection"
                defaultValue="auto"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Leakage detection dropping AUC/R2 threshold"
                defaultValue="0.999"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Max rows x columns for leakage"
                defaultValue="1000000"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Report permutation importance on original features"
                defaultValue="disabled"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Maximum number of rows to perform permutation based feature selection"
                defaultValue="1000000"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Maximum number of original features used"
                defaultValue="1000000"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Max number of original non-numeric features"
                defaultValue="0.95"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Max allowed fraction of uniques for int/float time series"
                defaultValue="30"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Max number of engineered features (-1=auto)"
                defaultValue="-1"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Correlation beyond which triggers to trigger feature selection model type"
                defaultValue="200"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="max allowed fraction of uniques for integer and categoricals cols"
                defaultValue="0.95"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="max number of unique values for int/float to be categoricals"
                defaultValue="50"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="max number of engineered features (-1=auto)"
                defaultValue="-1"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="correlation beyond which triggers mananicity constrains (if enabled)"
                defaultValue="0.1"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="max feature intraction depth"
                defaultValue="-1"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="enable target encoding (auto disables for time series)"
                defaultValue="auto"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              />
            </Grid>
          </Grid>
        </TabPanel>
      </DialogContent>
      {/* <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          
        </DialogActions>
      </Dialog> */}
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}
